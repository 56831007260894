import React from 'react';
import LocalizedStrings from 'react-localization';
import { Radiobutton } from './contact-form/fields/radio-button';
import {
  StyledDoubleLayoutGrid,
  StyledFormDiv,
  StyledFormHeading,
  StyledInputLabel,
} from './contact-form/fields/styled-components';
import { styled } from 'styled-components';

const StyledOccasionSelectorLayout = styled(StyledDoubleLayoutGrid)`
  column-gap: 10px;
  width: 100%;
`;

const StyledRadioGroupLabel = styled(StyledInputLabel)`
  margin-bottom: 0px;
`;

const StyledSelectorHeading = styled(StyledFormHeading)`
  font-size: 35px;

  @media (max-width: 425px) {
    font-size: 25px;
  }
`;

interface OccasionSelectorProps {
  currentForm: string;
  changeForm: (form: string) => void;
  selectedLanguage: string;
}

export const OccasionSelector: React.FC<OccasionSelectorProps> = ({
  currentForm,
  changeForm,
  selectedLanguage,
}) => {
  const translations = new LocalizedStrings({
    en: {
      heading: 'Who would you like to contact?',
      radioFieldLabel: 'Occasion *',
    },
    de: {
      heading: 'Wen möchten Sie kontaktieren?',
      radioFieldLabel: 'Anlass *',
    },
  });

  translations.setLanguage(selectedLanguage);

  return (
    <StyledFormDiv>
      <StyledSelectorHeading>{translations.heading}</StyledSelectorHeading>
      <StyledRadioGroupLabel>
        {translations.radioFieldLabel}
      </StyledRadioGroupLabel>
      <StyledOccasionSelectorLayout>
        <Radiobutton
          id="occasionSelectorRadioButton1"
          radioLabel="Sales"
          value={currentForm === 'sales'}
          onChange={() => changeForm('sales')}
          radioGroup="occasionSelector"
          dataCy="salesFormSelector"
        />
        <Radiobutton
          id="occasionSelectorRadioButton2"
          radioLabel="After Sales / Service"
          value={currentForm === 'afterSales'}
          onChange={() => changeForm('afterSales')}
          radioGroup="occasionSelector"
          dataCy="afterSalesFormSelector"
        />
        <Radiobutton
          id="occasionSelectorRadioButton3"
          radioLabel="Recruiting"
          value={currentForm === 'recruiting'}
          onChange={() => changeForm('recruiting')}
          radioGroup="occasionSelector"
          dataCy="recruitingFormSelector"
        />
      </StyledOccasionSelectorLayout>
    </StyledFormDiv>
  );
};
