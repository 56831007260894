import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  validationMessages,
  validationMessagesGerman,
} from '../types/validation-messages';
import { useState } from 'react';
import { TrainingCenterForm } from '../components/contact-form/training-form';
import { postTrainingCenterFormData } from '../services/api';
import { showErrorToast } from '../services/toast';
import LocalizedStrings from 'react-localization';
import {
  EXPERIENCE,
  EXPERIENCE_GERMAN,
} from '../components/contact-form/fields/dropdowns/values';

interface TrainingCenterFormContainerProps {
  selectedLanguage: string;
}

export const TrainingCenterFormContainer: React.FC<
  TrainingCenterFormContainerProps
> = ({ selectedLanguage }) => {
  const [dropdownOpen, setDropdownOpen] = useState<boolean[]>([false]);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);

  const handleDropdownToggle = (dropdownValues: boolean[], index: number) => {
    const newValues = dropdownValues;
    newValues[index] = !newValues[index];
    setDropdownOpen([...newValues]);
  };

  const translations = new LocalizedStrings({
    en: {
      heading: 'Contact form',
      companyInformation: 'Company details',
      participants: 'Participants',
      messageSection: 'Your Message',
      formFields: {
        trainingType: 'Type of training',
        desiredWeek: 'Desired calendar week',
        trainingLanguage: 'Training language',
        company: 'Company name',
        email: 'E-mail of the applicant',
        phoneNumber: 'Telephone number of the applicant',
        participantName: 'Participant (full name)',
        participantNamePlaceholder: 'First and last name',
        participantExperience: 'Experience with wind energy',
        participantExperienceOptions: EXPERIENCE,
        participantNoExperienceOption: 'None',
        addParticipantButton: 'Add participant',
        message: 'Comments and preferred wind turbine type',
      },
      validationMessages: {
        FIELD_REQUIRED: validationMessages.FIELD_REQUIRED,
        EMAIL_NOT_VALID: validationMessages.EMAIL_NOT_VALID,
        TOO_MANY_CHARACTERS_INPUT: validationMessages.TOO_MANY_CHARACTERS_INPUT,
        TOO_MANY_CHARACTERS_TEXTAREA:
          validationMessages.TOO_MANY_CHARACTERS_TEXTAREA,
      },
      requiredFields: 'The fields marked with * are mandatory.',
      submit: 'Submit',
      selectedLanguage: 'EN',
      successUrl: '/en/contact/success',
    },
    de: {
      heading: 'Anmeldeformular',
      companyInformation: 'Unternehmensangaben',
      participants: 'Teilnehmer',
      messageSection: 'Ihre Nachricht',
      formFields: {
        trainingType: 'Art des Trainings',
        desiredWeek: 'Gewünschte Kalenderwoche',
        trainingLanguage: 'Sprache des Trainings',
        company: 'Firma',
        email: 'E-Mail des Antragsteller',
        phoneNumber: 'Telefonnummer des Antragstellers',
        participantName: 'Teilnehmer (vollständiger Name)',
        participantNamePlaceholder: 'Vor- und Nachname',
        participantExperience: 'Erfahrung mit Windenergie',
        participantExperienceOptions: EXPERIENCE_GERMAN,
        participantNoExperienceOption: 'Keine',
        addParticipantButton: 'Teilnehmer hinzufügen',
        message: 'Kommentare und bevorzugter Anlagetyp',
      },
      validationMessages: {
        FIELD_REQUIRED: validationMessagesGerman.FIELD_REQUIRED,
        EMAIL_NOT_VALID: validationMessagesGerman.EMAIL_NOT_VALID,
        TOO_MANY_CHARACTERS_INPUT:
          validationMessagesGerman.TOO_MANY_CHARACTERS_INPUT,
        TOO_MANY_CHARACTERS_TEXTAREA:
          validationMessagesGerman.TOO_MANY_CHARACTERS_TEXTAREA,
      },
      requiredFields:
        'Die mit einem * gekennzeichneten Felder sind Pflichtfelder.',
      submit: 'Absenden',
      selectedLanguage: 'DE',
      successUrl: '/kontakt/success',
    },
  });

  translations.setLanguage(selectedLanguage);

  const validation = yup.object().shape({
    trainingType: yup
      .string()
      .required(translations.validationMessages.FIELD_REQUIRED)
      .max(50, translations.validationMessages.TOO_MANY_CHARACTERS_INPUT),
    desiredWeek: yup
      .string()
      .required(translations.validationMessages.FIELD_REQUIRED)
      .max(50, translations.validationMessages.TOO_MANY_CHARACTERS_INPUT),
    trainingLanguage: yup
      .string()
      .required(translations.validationMessages.FIELD_REQUIRED)
      .max(50, translations.validationMessages.TOO_MANY_CHARACTERS_INPUT),
    company: yup
      .string()
      .max(50, translations.validationMessages.TOO_MANY_CHARACTERS_INPUT),
    email: yup
      .string()
      .required(translations.validationMessages.FIELD_REQUIRED)
      .matches(
        /^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
        translations.validationMessages.EMAIL_NOT_VALID,
      )
      .max(50, translations.validationMessages.TOO_MANY_CHARACTERS_INPUT),
    phoneNumber: yup
      .string()
      .required(translations.validationMessages.FIELD_REQUIRED)
      .max(50, translations.validationMessages.TOO_MANY_CHARACTERS_INPUT),
    participants: yup
      .array()
      .of(
        yup
          .string()
          .max(50, translations.validationMessages.TOO_MANY_CHARACTERS_INPUT),
      ),
    message: yup
      .string()
      .max(1000, translations.validationMessages.TOO_MANY_CHARACTERS_TEXTAREA),
  });

  const formik = useFormik({
    initialValues: {
      trainingType: '',
      desiredWeek: '',
      trainingLanguage: '',
      company: '',
      email: '',
      phoneNumber: '',
      message: '',
      participants: [''],
      experiences: ['NONE'],
      captchaToken: '',
      selectedLanguage: translations.selectedLanguage,
    },
    validationSchema: validation,
    onSubmit: (values, { resetForm }) => {
      setFormSubmitting(true);
      postTrainingCenterFormData(values)
        .then(() => {
          resetForm();
          window.location.href = translations.successUrl;
        })
        .catch((error) => showErrorToast(error))
        .finally(() => setFormSubmitting(false));
    },
  });

  const addParticipant = () => {
    const participants = formik.values.participants;
    const experiences = formik.values.experiences;
    participants.push('');
    experiences.push('NONE');
    formik.setFieldValue('participants', [...participants]);
    formik.setFieldValue('experiences', [...experiences]);
  };

  const removeParticipant = (id: number) => {
    const participants = formik.values.participants;
    const experiences = formik.values.experiences;
    experiences.splice(id, 1);
    participants.splice(id, 1);
    formik.setFieldValue('participants', [...participants]);
    formik.setFieldValue('experiences', [...experiences]);
  };

  const changeParticipantName = (id: number, value: string) => {
    const participants = formik.values.participants;
    participants[id] = value;
    formik.setFieldValue('participants', [...participants]);
  };

  const changeParticipantExperience = (id: number, value?: string) => {
    const experiences = formik.values.experiences;
    experiences[id] = value === undefined ? 'NONE' : value;
    formik.setFieldValue('experiences', [...experiences]);
  };

  window.addEventListener('resize', () => {
    setViewportWidth(window.innerWidth);
  });

  return (
    <TrainingCenterForm
      dropdownsOpen={dropdownOpen}
      onDropdownToggle={handleDropdownToggle}
      addParticipant={addParticipant}
      removeParticipant={removeParticipant}
      changeParticipantName={changeParticipantName}
      changeParticipantExperience={changeParticipantExperience}
      onSubmit={formik.handleSubmit}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      values={formik.values}
      touched={formik.touched}
      errors={formik.errors}
      setFieldValue={formik.setFieldValue}
      formSubmitting={formSubmitting}
      viewportWidth={viewportWidth}
      translations={translations}
    />
  );
};
