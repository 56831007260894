import React, { CSSProperties } from 'react';
import styled from 'styled-components';

const StyledDiv = styled.div`
  width: 100%;
  margin-bottom: 40px;
  padding-bottom: 10px;
  border-bottom: 1px solid #d2e9df;
`;

const StyledParagraph = styled.p`
  margin-bottom: 30px;
  leading-trim: both;
  text-edge: cap;
  font-family: DINCompPro;
  font-size: 22px;
  font-weight: 700;
  line-height: 120%;
  text-align: left;

  @media (max-width: 425px) {
    font-size: 20px;
  }
`;

interface FormSectionProps {
  headingText?: string;
  children: React.ReactNode;
  extraStyles?: CSSProperties;
}

export const FormSection: React.FC<FormSectionProps> = ({
  headingText,
  children,
  extraStyles,
}) => (
  <StyledDiv style={extraStyles}>
    <StyledParagraph>{headingText}</StyledParagraph>
    {children}
  </StyledDiv>
);
