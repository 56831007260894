import React from 'react';
import styled from 'styled-components';
import { ButtonTypes } from '../../../types/input-types';

const StyledButton = styled.button`
  border: none;
  padding: 12px 20px 11px 22px;
  background-color: #00584d;
  color: white;
  display: flex;
  justify-content: center;
  vertical-align: middle;

  &:disabled {
    background-color: #e4edec;
    color: #90aaa6;
  }
`;

const StyledLightButton = styled.button`
  display: flex;
  padding: 10px 14px 10px 10px;
  margin: auto 0;
  justify-content: center;
  vertical-align: middle;
  color: #00584d;
  leading-trim: both;
  text-edge: cap;
  font-family: DINCompPro;
  font-size: 15px;
  font-weight: 400;
  line-height: 70%;
  border: 1px solid #84b1a1;

  &:disabled {
    background-color: white;
    color: #90aaa6;
    border: 1px solid #c9d8d6;
  }
`;

interface ButtonProps {
  children: React.ReactNode;
  type?: ButtonTypes;
  design?: string;
  onClick?: () => void;
  disabled?: boolean;
  dataCy?: string;
}

export const Button: React.FC<ButtonProps> = ({
  children,
  type = 'button',
  design = 'dark',
  onClick,
  disabled,
  dataCy,
}) => (
  <>
    {design === 'dark' ? (
      <StyledButton
        type={type}
        onClick={onClick}
        disabled={disabled}
        data-cy={dataCy}
      >
        {children}
      </StyledButton>
    ) : (
      <StyledLightButton
        type={type}
        onClick={onClick}
        disabled={disabled}
        data-cy={dataCy}
      >
        {children}
      </StyledLightButton>
    )}
  </>
);
