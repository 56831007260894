import React from 'react';

const XIcon: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 23"
    fill="none"
  >
    <path
      d="M5.27051 5.77051L16.7288 17.2288"
      stroke="#B8D9CC"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.27051 17.2288L16.7288 5.77051"
      stroke="#B8D9CC"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default XIcon;
