import styled from 'styled-components';

export const StyleErrorLabel = styled.span`
  margin-top: 8px;
  font-family: DINCompPro;
  color: #f00;
  display: flex;
  text-align: left;
`;

export const StyledFormDiv = styled.div`
  max-width: 890px;
  padding: 50px 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #fff;
  border-top: 4px solid #b8d9cc;
  text-align: left;

  @media (max-width: 425px) {
    padding: 20px;
  }
`;

export const StyledFormHeading = styled.h2`
  font-family: DINCompPro;
  margin-bottom: 40px;
  color: #004038;
  font-size: 44px;
  font-weight: 700;
  line-height: 58px;

  @media (max-width: 425px) {
    font-size: 25px;
  }
`;

export const StyledParagraph = styled.p<{ color?: string }>`
  margin: 0;
  font-family: DINCompPro;
  color: ${(props) => (props.color ? props.color : '#4c6e6a')};
  font-weight: 400;
  font-size: 17px;
  line-height: 24px;
  text-align: left;
`;

export const StyledBoldParagraph = styled(StyledParagraph)`
  font-family: DINCompPro;
  color: #20403c;
  font-weight: 700;
  text-align: left;
`;

export const StyledSmallParagraph = styled(StyledParagraph)`
  font-family: DINCompPro;
  color: #708c89;
  leading-trim: both;
  text-edge: cap;
  font-size: 15px;
  line-height: normal;
`;

export const StyledButtonParagraph = styled(StyledParagraph)<{
  color?: string;
}>`
  line-height: normal;
  leading-trim: both;
  text-edge: cap;
  display: flex;
  font-size: 17px;
`;

export const StyledInputWrapper = styled.div`
  width: 100%;
  padding-bottom: 30px;
`;

export const StyledInputLabel = styled(StyledParagraph)`
  margin-bottom: 10px;
  leading-trim: both;
  text-edge: cap;
  line-height: normal;
`;

export const StyledInput = styled.input`
  width: 100%;
  padding: 10px 12px;
  border: 1px solid #b8d9cc;
  border-radius: 0px;
  background-color: #f7faf8;
  color: #20403c;
  leading-trim: both;
  text-edge: cap;
  font-family: DINCompPro;
  font-size: 17px;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
`;

export const StyledLink = styled.a`
  font-family: DINCompPro;
  color: #74b95b;
  font-weight: 400;
  font-size: 17px;
  line-height: 24px;
  text-decoration-line: underline;
`;

export const StyledBoldLable = styled.b`
  font-family: DINCompPro;
  font-weight: 700;
  font-size: 17px;
  line-height: 24px;
`;

export const StyledSpacedFlex = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledCheckboxLabel = styled.label`
  margin: auto 0 auto 20px;
  width: 23.66px;
  height: 22px;
  border: 1px solid #b8d9cc;
  background-color: #f7faf8;
  background-position: -2px -1px;
  padding: 0;
`;

export const StyledCheckbox = styled.input`
  display: none;
`;

export const StyledDoubleLayoutGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: 32px;
  @media (max-width: 768px) {
    display: block;
`;

export const StyledTripleLayoutGrid = styled(StyledDoubleLayoutGrid)`
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  column-gap: 32px;
`;

export const StyledTwoColSpan = styled.div`
  grid-row: span 2 / span 2;
`;

export const StyledThreeColSpan = styled.div`
  grid-row: span 3 / span 3;
`;

export const ExpandedForm = styled.form`
  width: 100%;
`;

export const StyledErrorToastContainer = styled.div`
  position: fixed;
  right: 20px;
  bottom: 20px;
`;

export const StyledErrorToast = styled.div`
  padding: 20px;
  background-color: #e23f33;
  border-radius: 5px;
  color: white;
`;
