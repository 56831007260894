const baseUrl = 'https://web-smtp.enercon.de';

export const backEnds = {
  SALES: `${baseUrl}/v1/forms/sales`,
  AFTER_SALES: `${baseUrl}/v1/forms/after-sales`,
  RECRUITING: `${baseUrl}/v1/forms/recruiting`,
  COMMUNICATIONS: `${baseUrl}/v1/forms/communications`,
  NEWSLETTER: `${baseUrl}/v1/forms/newsletter`,
  TRAINING_CENTER: `${baseUrl}/v1/forms/training`,
};
