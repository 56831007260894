import React, { CSSProperties } from 'react';
import {
  StyledInput,
  StyledInputLabel,
  StyledInputWrapper,
  StyleErrorLabel,
} from './styled-components';

interface InputFieldProps {
  fieldLabel?: string;
  name: string;
  type?: string;
  value?: string;
  onChange: (e: React.FormEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FormEvent<HTMLInputElement>) => void;
  placeholder?: string;
  required?: boolean;
  error?: string;
  extraStyles?: CSSProperties;
  autocomplete?: string;
  dataCy?: string;
  dataCyError?: string;
}

export const InputField: React.FC<InputFieldProps> = ({
  name,
  type = 'text',
  value,
  onChange,
  onBlur,
  placeholder,
  fieldLabel,
  required,
  error,
  extraStyles,
  autocomplete,
  dataCy,
  dataCyError,
}) => (
  <StyledInputWrapper style={extraStyles}>
    {fieldLabel && (
      <StyledInputLabel>
        {required ? `${fieldLabel} *` : fieldLabel}
      </StyledInputLabel>
    )}
    <StyledInput
      name={name}
      type={type}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      placeholder={placeholder}
      autoComplete={autocomplete}
      data-cy={dataCy}
    />
    {error && <StyleErrorLabel data-cy={dataCyError}>{error}</StyleErrorLabel>}
  </StyledInputWrapper>
);
