import React from 'react';
import Lottie from 'lottie-react';
import animationData from '../../../assets/lottie/loading-spinner.json';
import styled from 'styled-components';

const LottieFrame = styled.div`
  margin: 0 auto;
  width: 20px;
  height: 20px;
`;

export const LoadingSpinner: React.FC = () => (
  <LottieFrame data-cy="loadingSpinner">
    <Lottie
      className="Loading-spinner"
      loop={true}
      autoplay={true}
      animationData={animationData}
      rendererSettings={{
        preserveAspectRatio: 'xMidYMid slice',
      }}
      height={20}
      width={20}
    />
  </LottieFrame>
);
