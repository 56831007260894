import React from 'react';

const RadioboxIcon: React.FC = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="radiobox">
      <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" fill="#00584D" />
      <path
        id="Vector 57"
        d="M5.88281 10.0461L8.58177 12.745L13.8759 7.45093"
        stroke="white"
        stroke-width="1.5"
      />
      <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" stroke="#74B95B" />
    </g>
  </svg>
);

export default RadioboxIcon;
