import React from 'react';
import styled from 'styled-components';
import {
  StyledCheckbox,
  StyledInputWrapper,
  StyledCheckboxLabel,
  StyleErrorLabel,
} from './styled-components';
import CheckboxCheckedIcon from '../../../assets/icons/checkbox-checked-icon';

const StyledWrapper = styled(StyledInputWrapper)`
  display: flex;
`;

const StyledDiv = styled.div`
  margin-left: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

interface CheckboxProps {
  id: string;
  name: string;
  value?: boolean;
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FormEvent<HTMLInputElement>) => void;
  children: React.ReactNode;
  required?: boolean;
  error?: string;
  dataCy?: string;
  dataCyError?: string;
}

export const Checkbox: React.FC<CheckboxProps> = ({
  id,
  name,
  value,
  onChange,
  onBlur,
  children,
  required,
  error,
  dataCy,
  dataCyError,
}) => (
  <>
    <StyledWrapper>
      <StyledCheckbox
        id="checkbox"
        type="checkbox"
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        checked={value}
      />
      <StyledCheckboxLabel htmlFor="checkbox" data-cy={dataCy}>
        <CheckboxCheckedIcon
          width={value ? '22' : '0'}
          height={value ? '22' : '0'}
        />
      </StyledCheckboxLabel>
      <StyledDiv>{children}</StyledDiv>
    </StyledWrapper>
    {error && <StyleErrorLabel data-cy={dataCyError}>{error}</StyleErrorLabel>}
  </>
);
