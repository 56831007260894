import React from 'react';
import styled from 'styled-components';
import PositionMarkerIcon from '../../assets/icons/position-marker-icon';

const StyledMarkerInfobox = styled.div`
  position: absolute;
  bottom: 12px;
  left: 50%;
  transform: translate(-50%, -20px);
  background-color: white;
  display: inline-flex;
  padding: 10px 16px 12px 12px;
  flex-direction: column;
  align-items: flex-start;
  white-space: nowrap;
  width: auto;
  visibility: hidden;
  opacity: 0;
  transition: 0.15s;
  gap: 8px;
  z-index: 10;
`;

const StyledMarkerImage = styled.div`
  position: absolute;
  width: 25.575px;
  height: 25.575px;

  &:hover ${StyledMarkerInfobox} {
    transform: translate(-50%, 0px);
    visibility: visible;
    opacity: 1;
  }
`;

const StyledMarkerTooltipHolder = styled.div`
  position: relative;
  transform: translateY(-24px);
  z-index: 999;

  &:hover ${StyledMarkerInfobox} {
    transform: translate(-50%, -20px);
    visibility: hidden;
    opacity: 0;
  }
`;

const StyledContinentText = styled.p`
  margin: 0;
  color: #4c8a7a;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const StyledCountryText = styled(StyledContinentText)`
  color: #004038;
  font-family: DINCompPro;
  font-size: 16px;
  font-weight: 700;
`;

const StyledMarkerArrow = styled.div`
  background-color: white;
  width: 12px;
  height: 12px;
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translate(-50%, -6px) rotate(45deg);
`;

interface PositionMarkerProps {
  left: string;
  top: string;
  continent: string;
  country: string;
}

export const PositionMarker: React.FC<PositionMarkerProps> = ({
  left,
  top,
  continent,
  country,
}) => {
  return (
    <>
      <StyledMarkerImage
        style={{
          left: left,
          top: top,
        }}
      >
        <PositionMarkerIcon />
        <StyledMarkerTooltipHolder>
          <StyledMarkerInfobox>
            <StyledContinentText>{continent}</StyledContinentText>
            <StyledCountryText>{country}</StyledCountryText>
            <StyledMarkerArrow />
          </StyledMarkerInfobox>
        </StyledMarkerTooltipHolder>
      </StyledMarkerImage>
    </>
  );
};
