import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import {
  StyledInputLabel,
  StyledInputWrapper,
  StyleErrorLabel,
} from './styled-components';

const StyledTextArea = styled.textarea`
  width: 100%;
  padding: 16px 4px 16px 12px;
  border: 1px solid #b8d9cc;
  background-color: #f7faf8;
  color: #20403c;
  resize: none;
  font-family: DINCompPro;
`;

interface TextareaProps {
  fieldLabel?: string;
  name: string;
  value?: string;
  placeholder?: string;
  onChange: (e: React.FormEvent<HTMLTextAreaElement>) => void;
  onBlur?: (e: React.FormEvent<HTMLTextAreaElement>) => void;
  required?: boolean;
  error?: string;
  height?: string;
  extraStyles?: CSSProperties;
  dataCy?: string;
  dataCyError?: string;
}

export const Textarea: React.FC<TextareaProps> = ({
  fieldLabel,
  name,
  value,
  placeholder,
  onChange,
  onBlur,
  required,
  error,
  height,
  extraStyles,
  dataCy,
  dataCyError,
}) => (
  <StyledInputWrapper style={extraStyles}>
    {fieldLabel && (
      <StyledInputLabel>
        {required ? `${fieldLabel} *` : fieldLabel}
      </StyledInputLabel>
    )}
    <StyledTextArea
      name={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      placeholder={placeholder}
      style={{ height: height || '90px' }}
      data-cy={dataCy}
    />
    {error && <StyleErrorLabel data-cy={dataCyError}>{error}</StyleErrorLabel>}
  </StyledInputWrapper>
);
