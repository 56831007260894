import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { validationMessages } from '../types/validation-messages';
import { NewsletterForm } from '../components/contact-form/newsletter-form';
import { postNewsletterFormData } from '../services/api';
import { showErrorToast } from '../services/toast';

export const NewsletterFormContainer: React.FC = () => {
  const [salutationOpen, setSalutationOpen] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);

  const validation = yup.object().shape({
    company: yup.string().max(50, validationMessages.TOO_MANY_CHARACTERS_INPUT),
    salutation: yup.string().required(validationMessages.SELECTION_REQUIRED),
    firstName: yup
      .string()
      .required(validationMessages.FIELD_REQUIRED)
      .max(50, validationMessages.TOO_MANY_CHARACTERS_INPUT),
    name: yup
      .string()
      .required(validationMessages.FIELD_REQUIRED)
      .max(50, validationMessages.TOO_MANY_CHARACTERS_INPUT),
    email: yup
      .string()
      .required(validationMessages.FIELD_REQUIRED)
      .matches(
        /^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
        validationMessages.EMAIL_NOT_VALID,
      )
      .max(50, validationMessages.TOO_MANY_CHARACTERS_INPUT),
  });

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: {
      company: '',
      salutation: '',
      name: '',
      firstName: '',
      email: '',
      captchaToken: '',
    },
    validationSchema: validation,
    onSubmit: (values, { resetForm }) => {
      setFormSubmitting(true);
      postNewsletterFormData(values)
        .then(() => {
          resetForm();
          window.location.href = '/kontakt/success';
        })
        .catch((error) => showErrorToast(`Fehler: ${error}`));
    },
  });

  return (
    <NewsletterForm
      salutationOpen={salutationOpen}
      setSalutationOpen={setSalutationOpen}
      onSubmit={formik.handleSubmit}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      values={formik.values}
      touched={formik.touched}
      errors={formik.errors}
      setFieldTouched={formik.setFieldTouched}
      setFieldValue={formik.setFieldValue}
      formSubmitting={formSubmitting}
    />
  );
};
