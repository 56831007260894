import React from 'react';
import styled from 'styled-components';

const StyledGapedSVG = styled.svg`
  margin: auto 0 auto 8px;
`;

const SubmitArrowIcon: React.FC = () => (
  <StyledGapedSVG
    width="8"
    height="12"
    viewBox="0 0 8 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="Vector 59"
      d="M1 1L6 6L1 11"
      stroke="#84B1A1"
      stroke-width="1.5"
      stroke-linecap="round"
    />
  </StyledGapedSVG>
);

export default SubmitArrowIcon;
