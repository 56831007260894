import { toast } from 'react-toastify';

export const showErrorToast = (message: string) =>
  toast.error(message, {
    position: 'bottom-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'colored',
    bodyStyle: {
      color: '#fff',
      fontSize: '14px',
      textAlign: 'left',
    },
  });
