import React from 'react';

const CloudUploadIcon: React.FC = () => (
  <svg
    width="34"
    height="35"
    viewBox="0 0 34 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="cloud-upload.1.1 1" clip-path="url(#clip0_2320_50145)">
      <path
        id="Vector"
        d="M21.25 21.75L17 17.5"
        stroke="#84B1A1"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_2"
        d="M17 17.5L12.75 21.75"
        stroke="#84B1A1"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_3"
        d="M17 28.8333V17.5"
        stroke="#84B1A1"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_4"
        d="M11.3334 27.4167H8.50004C6.99715 27.4167 5.55581 26.8196 4.4931 25.7569C3.4304 24.6942 2.83337 23.2529 2.83337 21.75C2.83337 20.2471 3.4304 18.8058 4.4931 17.7431C5.55581 16.6803 6.99715 16.0833 8.50004 16.0833L8.48587 15.9836C8.31161 14.7638 8.4015 13.5207 8.74946 12.3387C9.09742 11.1566 9.69532 10.0631 10.5027 9.13218C11.31 8.20128 12.308 7.45474 13.429 6.94309C14.55 6.43145 15.7678 6.16667 17 6.16666C18.2323 6.16667 19.4501 6.43145 20.5711 6.94309C21.6921 7.45474 22.6901 8.20128 23.4974 9.13218C24.3048 10.0631 24.9027 11.1566 25.2506 12.3387C25.5986 13.5207 25.6885 14.7638 25.5142 15.9836L25.5 16.0833C27.0029 16.0833 28.4443 16.6803 29.507 17.7431C30.5697 18.8058 31.1667 20.2471 31.1667 21.75C31.1667 23.2529 30.5697 24.6942 29.507 25.7569C28.4443 26.8196 27.0029 27.4167 25.5 27.4167H22.6667"
        stroke="#84B1A1"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2320_50145">
        <rect
          width="34"
          height="34"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default CloudUploadIcon;
