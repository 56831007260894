export function convertToFormData(data: any): FormData {
  const formData = new FormData();
  Object.entries(data).forEach((entry) => {
    if (entry[1] !== undefined && entry[1] !== null) {
      if (entry[1] instanceof File) {
        formData.append(entry[0], entry[1]);
      } else if (typeof entry[1] === 'boolean') {
        formData.append(entry[0], entry[1] ? '1' : '0');
      } else if (typeof entry[1] !== 'undefined') {
        formData.append(entry[0], (entry[1] as any).toString());
      }
    }
  });
  return formData;
}
