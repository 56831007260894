import React from 'react';

const DropdownArrowIcon: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Frame 115">
      <path
        id="arrow"
        d="M6 9L12 15L18 9"
        stroke="#00584D"
        stroke-width="1.5"
        stroke-linecap="round"
      />
    </g>
  </svg>
);

export default DropdownArrowIcon;
