import React from 'react';
import styled from 'styled-components';

const StyledMarginSVG = styled.svg`
  margin: auto;
`;

const XIconWhite: React.FC = () => (
  <StyledMarginSVG
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 1L13 13"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1 13L13 1"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </StyledMarginSVG>
);

export default XIconWhite;
