import React from 'react';
import styled from 'styled-components';
import { MarkerInfos } from '../../types/map-types';
import { PositionMarker } from './position-marker';

const StyledMapContainer = styled.div`
  width: 100%;
  aspect-ratio: 16/9;
  position: relative;
  background-repeat: no-repeat;
  background-size: 100% 100%;
`;

interface MapProps {
  markers: MarkerInfos[];
}

export const Map: React.FC<MapProps> = ({ markers }) => (
  <StyledMapContainer
    style={{
      backgroundImage: `url("https://merry-fairy-24c00f.netlify.app/map-layer.svg")`,
    }}
  >
    {markers.map((marker) => (
      <PositionMarker
        key={marker.country}
        left={marker.left}
        top={marker.top}
        continent={marker.continent}
        country={marker.country}
      />
    ))}
  </StyledMapContainer>
);
