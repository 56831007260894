import React from 'react';
import styled from 'styled-components';

const StyledAnimatedSVG = styled.svg`
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  transform-origin: left;
`;

interface CheckboxCheckedIconProps {
  width?: string;
  height?: string;
}

const CheckboxCheckedIcon: React.FC<CheckboxCheckedIconProps> = ({
  width = '20',
  height = '20',
}) => (
  <StyledAnimatedSVG
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="state=checked">
      <rect x="0.5" y="0.5" width="19" height="19" fill="#F8FAF9" />
      <path
        id="Vector"
        d="M5 10L8.5 13.5L15 7"
        stroke="#00584D"
        stroke-width="2"
        stroke-linecap="square"
      />
      <rect x="0.5" y="0.5" width="19" height="19" stroke="#B8D9CC" />
    </g>
  </StyledAnimatedSVG>
);

export default CheckboxCheckedIcon;
