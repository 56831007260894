import React from 'react';

const UploadedFileIcon: React.FC = () => (
  <svg
    width="34"
    height="35"
    viewBox="0 0 34 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0931 25.9998V21.0415C10.0931 20.4779 10.317 19.9374 10.7155 19.5389C11.1141 19.1404 11.6546 18.9165 12.2181 18.9165C12.7817 18.9165 13.3222 19.1404 13.7207 19.5389C14.1193 19.9374 14.3431 20.4779 14.3431 21.0415V25.9998C14.3431 27.127 13.8954 28.208 13.0983 29.005C12.3013 29.8021 11.2203 30.2498 10.0931 30.2498C8.96597 30.2498 7.88497 29.8021 7.08794 29.005C6.29091 28.208 5.84314 27.127 5.84314 25.9998V21.7498"
      stroke="#84B1A1"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.4167 30.25H24.0834C25.2105 30.25 26.2915 29.8022 27.0886 29.0052C27.8856 28.2082 28.3334 27.1272 28.3334 26V12.1771C28.3334 11.0499 27.8856 9.9689 27.0886 9.17187L23.9115 5.9948C23.1145 5.19777 22.0335 4.75 20.9063 4.75H9.91669C8.78952 4.75 7.70851 5.19777 6.91148 5.9948C6.11445 6.79183 5.66669 7.87283 5.66669 9V16.0833"
      stroke="#84B1A1"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M28.3334 12.5417H23.375C22.6236 12.5417 21.9029 12.2432 21.3716 11.7118C20.8402 11.1804 20.5417 10.4598 20.5417 9.70833V4.75"
      stroke="#84B1A1"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default UploadedFileIcon;
