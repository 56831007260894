import React from 'react';
import { StyledDoubleLayoutGrid } from '../fields/styled-components';

interface DoubleLayoutProps {
  children: React.ReactNode;
}

export const DoubleLayout: React.FC<DoubleLayoutProps> = ({ children }) => (
  <StyledDoubleLayoutGrid>{children}</StyledDoubleLayoutGrid>
);
