export const validationMessagesGerman = {
  FIELD_REQUIRED: 'Bitte fülle dieses Feld aus.',
  EMAIL_NOT_VALID: 'Eingabe entspricht nicht dem vorgegebenen Email Format.',
  FILE_WRONG_TYPE: 'Es dürfen nur PDF Dateien hochgeladen werden.',
  FILE_WRONG_SIZE: 'Die Datei darf nicht größer als 10 MB sein.',
  FILE_WRONG_COUNT: 'Es darf nur eine Datei hochgeladen werden.',
  SELECTION_REQUIRED: 'Bitte eine Option auswählen.',
  DECLARATION_REQUIRED: 'Bitte stimmen Sie den Datenschutzbestimmungen zu.',
  TOO_MANY_CHARACTERS_INPUT: 'Die Eingabe darf 50 Zeichen nicht überschreiten.',
  TOO_MANY_CHARACTERS_TEXTAREA:
    'Die Eingabe darf 1000 Zeichen nicht überschreiten.',
};

export const validationMessages = {
  FIELD_REQUIRED: 'Please fill out this field.',
  EMAIL_NOT_VALID: 'Please enter a valid email.',
  FILE_WRONG_TYPE: 'Only PDF files are allowed to be uploaded.',
  FILE_WRONG_SIZE: 'The file may not be larger than 10 MB.',
  FILE_WRONG_COUNT: 'Only one file may be uploaded.',
  SELECTION_REQUIRED: 'Please select an option.',
  DECLARATION_REQUIRED: 'Please agree to the privacy policy.',
  TOO_MANY_CHARACTERS_INPUT: 'The input may not exceed 50 characters.',
  TOO_MANY_CHARACTERS_TEXTAREA: 'The input may not exceed 1000 characters.',
};
