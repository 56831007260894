import React, { useState } from 'react';
import styled from 'styled-components';
import { RecruitingFormContainer } from '../containers/recruiting-form.container';
import { SalesFormContainer } from '../containers/sales-form.container';
import { AfterSalesFormContainer } from '../containers/after-sales-form.container';
import { NewsletterFormContainer } from '../containers/newsletter-form.container';
import { OccasionSelector } from './occasion-selector';
import { TrainingCenterFormContainer } from '../containers/training-form.container';
import { MapSection } from './interactive-map/map-section';
import { ToastContainer } from 'react-toastify';

const StyledMainWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledSpacerDiv = styled.div`
  margin-top: 30px;
`;

interface ContactFormsProps {
  id: string;
}

export const ContactForms: React.FC<ContactFormsProps> = ({ id }) => {
  const reactRoot = document.getElementById(id);
  const moduleId = reactRoot?.getAttribute('data-module');
  const formId = reactRoot?.getAttribute('data-form');
  const language = reactRoot?.getAttribute('data-lang') || 'en';


  const [form, setForm] = useState(formId || 'sales');

  return (
    <StyledMainWrapper>
      {moduleId === 'contact' && (
        <>
          <OccasionSelector
            currentForm={form}
            changeForm={(value) => setForm(value)}
            selectedLanguage={language}
          />
          <StyledSpacerDiv>
            {form === 'sales' && (
              <SalesFormContainer selectedLanguage={language} />
            )}
            {form === 'afterSales' && (
              <AfterSalesFormContainer selectedLanguage={language} />
            )}
            {form === 'recruiting' && (
              <RecruitingFormContainer selectedLanguage={language} />
            )}
          </StyledSpacerDiv>
        </>
      )}
      {moduleId === 'newsletter' && <NewsletterFormContainer />}
      {moduleId === 'training' && (
        <TrainingCenterFormContainer selectedLanguage={language} />
      )}
      {moduleId === 'map' && <MapSection selectedLanguage={language} />}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        limit={5}
      />
    </StyledMainWrapper>
  );
};
