import React from 'react';
import { DoubleLayout } from './wrappers/double-layout';
import { FormSection } from './wrappers/form-section';
import { InputField } from './fields/input-field';
import { Textarea } from './fields/textarea';
import { Checkbox } from './fields/checkbox';
import { Button } from './fields/button';
import CountrySelector from './fields/dropdowns/selector';
import { SelectorType } from '../../types/dropdown-types';
import {
  StyledBoldParagraph,
  StyledButtonParagraph,
  StyledFormDiv,
  StyledFormHeading,
  StyledLink,
  StyledParagraph,
  StyledSmallParagraph,
  StyledSpacedFlex,
} from './fields/styled-components';
import styled from 'styled-components';
import Dropdown from './fields/dropdowns/dropdown';
import { FileDragAndDrop } from './fields/file-dnd';
import {
  RecruitingFormErrors,
  RecruitingFormTouched,
  RecruitingFormValues,
} from '../../types/form-data-types';
import { LoadingSpinner } from './fields/loading-spinner';
import { FriendlyCaptcha } from '../friendly-captcha';
import SubmitArrowIcon from '../../assets/icons/submit-arrow-icon';
import { LocalizedStrings } from 'react-localization';

const LoadingButtonSizer = styled.div`
  margin: 0 auto;
  width: 90.484px;
  height: 21px;
`;

const CenteredParagraph = styled(StyledSmallParagraph)`
  margin: auto 0;
`;

interface RecruitingFormProps {
  countryOpen: boolean;
  salutationOpen: boolean;
  departmentOpen: boolean;
  channelOpen: boolean;
  setCountryOpen: (val: boolean) => void;
  setSalutationOpen: (val: boolean) => void;
  setDepartmentOpen: (val: boolean) => void;
  setChannelOpen: (val: boolean) => void;
  onSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  onChange: (e: React.ChangeEvent<any>) => void;
  onBlur: (e: React.FormEvent<Element>) => void;
  values: RecruitingFormValues;
  touched: RecruitingFormTouched;
  errors: RecruitingFormErrors;
  setFieldValue: (field: string, value: any) => void;
  setFieldTouched: (field: string, field1: boolean, field2: boolean) => void;
  setFile: (file: File[]) => void;
  removeFile: () => void;
  file?: File;
  filesError?: string;
  formSubmitting: boolean;
  translations: LocalizedStrings<any>;
}

export const RecruitingForm: React.FC<RecruitingFormProps> = ({
  countryOpen,
  salutationOpen,
  departmentOpen,
  channelOpen,
  setCountryOpen,
  setSalutationOpen,
  setDepartmentOpen,
  setChannelOpen,
  onSubmit,
  onChange,
  onBlur,
  values,
  touched,
  errors,
  setFieldValue,
  setFieldTouched,
  setFile,
  removeFile,
  file,
  filesError,
  formSubmitting,
  translations,
}) => {
  return (
    <StyledFormDiv>
      <StyledFormHeading>{translations.heading}</StyledFormHeading>
      <form onSubmit={onSubmit}>
        <FormSection headingText={translations.personalInformation}>
          <DoubleLayout>
            <Dropdown
              id="salutation"
              fieldLabel={translations.formFields.salutation}
              onChange={(val) => setFieldValue('salutation', val)}
              open={salutationOpen}
              onToggle={() => setSalutationOpen(!salutationOpen)}
              value={values.salutation}
              placeholder={translations.formFields.dropdownSelect}
              removeSelectionText={translations.formFields.dropdownRemove}
              options={translations.formFields.salutationValues}
              required
              onBlur={() => setFieldTouched('salutation', true, true)}
              error={touched.salutation ? errors.salutation : ''}
              dataCySelector="recruitingFormSalutationDropdown"
              dataCyOptions="recruitingFormSalutationOption"
              dataCyError="recruitingFormSalutationDropdownError"
            />
            <InputField
              name="title"
              value={values.title}
              onChange={onChange}
              onBlur={onBlur}
              fieldLabel={translations.formFields.title}
              autocomplete="honorific-suffix"
              error={touched.title ? errors.title : ''}
              dataCy="recruitingFormTitleField"
              dataCyError="recruitingFormTitleFieldError"
            />
            <InputField
              name="firstName"
              value={values.firstName}
              onChange={onChange}
              onBlur={onBlur}
              fieldLabel={translations.formFields.firstName}
              required
              error={touched.firstName ? errors.firstName : ''}
              autocomplete="given-name"
              dataCy="recruitingFormFirstNameField"
              dataCyError="recruitingFormFirstNameFieldError"
            />
            <InputField
              name="lastName"
              value={values.lastName}
              onChange={onChange}
              onBlur={onBlur}
              fieldLabel={translations.formFields.lastName}
              required
              error={touched.lastName ? errors.lastName : ''}
              autocomplete="family-name"
              dataCy="recruitingFormLastNameField"
              dataCyError="recruitingFormLastNameFieldError"
            />
          </DoubleLayout>
        </FormSection>

        <FormSection headingText={translations.residence}>
          <DoubleLayout>
            <InputField
              name="street"
              value={values.street}
              onChange={onChange}
              onBlur={onBlur}
              fieldLabel={translations.formFields.street}
              required
              error={touched.street ? errors.street : ''}
              dataCy="recruitingFormStreetField"
              dataCyError="recruitingFormStreetFieldError"
            />
            <InputField
              name="houseNumber"
              value={values.houseNumber}
              onChange={onChange}
              onBlur={onBlur}
              fieldLabel={translations.formFields.houseNumber}
              required
              error={touched.houseNumber ? errors.houseNumber : ''}
              dataCy="recruitingFormHouseNumberField"
              dataCyError="recruitingFormHouseNumberFieldError"
            />
            <InputField
              name="postalCode"
              value={values.postalCode}
              onChange={onChange}
              onBlur={onBlur}
              fieldLabel={translations.formFields.postalCode}
              required
              error={touched.postalCode ? errors.postalCode : ''}
              dataCy="recruitingFormPostalCodeField"
              dataCyError="recruitingFormPostalCodeFieldError"
            />
            <InputField
              name="city"
              value={values.city}
              onChange={onChange}
              onBlur={onBlur}
              fieldLabel={translations.formFields.city}
              required
              error={touched.city ? errors.city : ''}
              dataCy="recruitingFormCityField"
              dataCyError="recruitingFormCityFieldError"
            />
            <InputField
              name="addressSuffix"
              value={values.addressSuffix}
              onChange={onChange}
              onBlur={onBlur}
              fieldLabel={translations.formFields.addressSuffix}
              error={touched.addressSuffix ? errors.addressSuffix : ''}
              dataCy="recruitingFormAddressSuffixField"
              dataCyError="recruitingFormAddressSuffixFieldError"
            />
            <CountrySelector
              id=""
              fieldLabel={translations.formFields.country}
              open={countryOpen}
              onToggle={() => setCountryOpen(!countryOpen)}
              onChange={(val) => (values.country = val)}
              selectedValue={(
                translations.formFields.countryValues as SelectorType[]
              ).find((option) => option.value === values.country)}
              options={translations.formFields.countryValues}
              searchPlaceholder={
                translations.formFields.countrySearchPlaceholder
              }
              required
            />
          </DoubleLayout>
        </FormSection>

        <FormSection headingText={translations.contact}>
          <DoubleLayout>
            <InputField
              name="email"
              value={values.email}
              onChange={onChange}
              onBlur={onBlur}
              type="email"
              fieldLabel="E-Mail"
              required
              error={touched.email ? errors.email : ''}
              autocomplete="email"
              dataCy="recruitingFormEmailField"
              dataCyError="recruitingFormEmailFieldError"
            />
            <InputField
              name="phoneNumber"
              value={values.phoneNumber}
              onChange={onChange}
              onBlur={onBlur}
              fieldLabel={translations.formFields.phoneNumber}
              error={touched.phoneNumber ? errors.phoneNumber : ''}
              autocomplete="tel"
              dataCy="recruitingFormPhoneNumberField"
              dataCyError="recruitingFormPhoneNumberFieldError"
            />
          </DoubleLayout>
        </FormSection>

        <FormSection headingText={translations.interest}>
          <Dropdown
            id="department"
            fieldLabel={translations.formFields.department}
            onChange={(val) => (values.department = val)}
            open={departmentOpen}
            onToggle={() => setDepartmentOpen(!departmentOpen)}
            value={values.department}
            placeholder={translations.formFields.dropdownSelect}
            placeholderClickable
            removeSelectionText={translations.formFields.dropdownRemove}
            options={translations.formFields.departmentValues}
            onBlur={() => setFieldTouched('department', true, true)}
            error={errors.department}
            dataCySelector="recruitingFormDepartmentSelector"
            dataCyOptions="recruitingFormDepartmentOption"
            dataCyPlaceholder="recruitingFormDepartmentPlaceholder"
          />
        </FormSection>

        <FormSection headingText={translations.formFields.attachment}>
          <FileDragAndDrop
            name="file"
            file={file}
            onChange={(file: File[]) => setFile(file)}
            onRemoveFile={removeFile}
            fieldLabel={translations.formFields.fileType}
            uploadText={translations.formFields.uploadText}
            typeText={translations.formFields.typeText}
            buttonText={translations.formFields.fileButtonText}
            error={filesError}
            dataCy="recruitingFormFileDragAndDrop"
            dataCyInput="recruitingFormFileDragAndDropInput"
            dataCyButton="recruitingFormFileDragAndDropButton"
          />
        </FormSection>

        <FormSection headingText={translations.others}>
          <Textarea
            name="message"
            value={values.message}
            onChange={onChange}
            onBlur={onBlur}
            fieldLabel={translations.formFields.message}
            error={touched.message ? errors.message : ''}
            dataCy="recruitingFormMessageField"
            dataCyError="recruitingFormMessageFieldError"
          />
          <Dropdown
            id="visitedChannel"
            fieldLabel={translations.formFields.awareness}
            onChange={(val) => (values.visitedChannel = val)}
            open={channelOpen}
            onToggle={() => setChannelOpen(!channelOpen)}
            value={values.visitedChannel}
            placeholder={translations.formFields.dropdownSelect}
            placeholderClickable
            removeSelectionText={translations.formFields.dropdownRemove}
            options={translations.formFields.channelValues}
            onBlur={() => setFieldTouched('visitedChannel', true, true)}
            error={errors.visitedChannel}
            dataCySelector="recruitingFormVisitedChannelSelector"
            dataCyOptions="recruitingFormVisitedChannelOption"
            dataCyPlaceholder="recruitingFormVisitedChannelPlaceholder"
          />
        </FormSection>

        <FriendlyCaptcha
          onChange={(value) => setFieldValue('captchaToken', value)}
        />

        <FormSection>
          <Checkbox
            id="declarationOfConsent"
            name="declarationOfConsent"
            value={values.declarationOfConsent}
            onChange={onChange}
            onBlur={onBlur}
            error={
              touched.declarationOfConsent ? errors.declarationOfConsent : ''
            }
            dataCy="recruitingFormConsentCheckbox"
            dataCyError="recruitingFormConsentCheckboxError"
          >
            <StyledBoldParagraph>
              {translations.declarationHeading}
            </StyledBoldParagraph>
            <StyledParagraph>
              {translations.declarationText1}
              <StyledLink
                href={translations.declarationSiteLinkAddress}
                target="_blank"
                rel="noopener"
              >
                {translations.declarationText2}
              </StyledLink>
              {translations.declarationText3}
            </StyledParagraph>
          </Checkbox>
        </FormSection>
        <StyledSpacedFlex>
          <CenteredParagraph>{translations.requiredField}</CenteredParagraph>
          <Button
            type="submit"
            disabled={values.captchaToken === '' || formSubmitting}
            dataCy="recruitingFormSubmitButton"
          >
            {formSubmitting ? (
              <LoadingButtonSizer>
                <LoadingSpinner />
              </LoadingButtonSizer>
            ) : (
              <StyledButtonParagraph
                color={
                  values.captchaToken === '' || formSubmitting
                    ? '#90AAA6'
                    : 'white'
                }
              >
                {translations.submit} <SubmitArrowIcon />
              </StyledButtonParagraph>
            )}
          </Button>
        </StyledSpacedFlex>
      </form>
    </StyledFormDiv>
  );
};
