import { useFormik } from 'formik';
import React, { useState } from 'react';
import * as yup from 'yup';
import {
  validationMessages,
  validationMessagesGerman,
} from '../types/validation-messages';
import { postRecruitingFormData } from '../services/api';
import { RecruitingForm } from '../components/contact-form/recruiting-form';
import { showErrorToast } from '../services/toast';
import {
  CHANNELS,
  CHANNELS_GERMAN,
  COUNTRIES,
  COUNTRIES_GERMAN,
  DEPARTMENTS,
  DEPARTMENTS_GERMAN,
  SALUTATIONS,
  SALUTATIONS_GERMAN,
} from '../components/contact-form/fields/dropdowns/values';
import LocalizedStrings from 'react-localization';

interface RecruitingFormContainerProps {
  selectedLanguage: string;
}

export const RecruitingFormContainer: React.FC<
  RecruitingFormContainerProps
> = ({ selectedLanguage }) => {
  const [countryOpen, setCountryOpen] = useState(false);
  const [salutationOpen, setSalutationOpen] = useState(false);
  const [departmentOpen, setDepartmentOpen] = useState(false);
  const [channelOpen, setChannelOpen] = useState(false);
  const [file, setFile] = useState<File | undefined>();
  const [filesError, setFilesError] = useState('');
  const [formSubmitting, setFormSubmitting] = useState(false);

  const translations = new LocalizedStrings({
    en: {
      heading: 'Contact form',
      personalInformation: 'Personal information',
      residence: 'Place of residence',
      interest: 'What are you interested in?',
      attachment: 'Attachment',
      others: 'Other questions',
      contact: 'Contact details',
      message: 'Your Message',
      formFields: {
        salutation: 'Salutation',
        title: 'Title',
        firstName: 'First name',
        lastName: 'Last name',
        phoneNumber: 'Phone',
        street: 'Street',
        houseNumber: 'House number',
        postalCode: 'Postal code',
        city: 'City',
        addressSuffix: 'Address Suffix',
        country: 'Country',
        countrySearchPlaceholder: 'Search country',
        department: 'Area of interest',
        fileType: 'Curriculum vitae, application etc.',
        uploadDocument: 'Upload document',
        uploadText: 'Upload document',
        typeText: 'PDF (maximum 10MB)',
        fileButtonText: 'Search',
        awareness: 'I became aware of ENERCON through the following channel',
        message: 'Message for questions or comments',
        salutationValues: SALUTATIONS,
        countryValues: COUNTRIES,
        departmentValues: DEPARTMENTS,
        channelValues: CHANNELS,
        dropdownSelect: 'Choose',
        dropdownRemove: 'Remove selection',
      },
      validationMessages: {
        FIELD_REQUIRED: validationMessages.FIELD_REQUIRED,
        EMAIL_NOT_VALID: validationMessages.EMAIL_NOT_VALID,
        FILE_WRONG_TYPE: validationMessages.FILE_WRONG_TYPE,
        FILE_WRONG_SIZE: validationMessages.FILE_WRONG_SIZE,
        FILE_WRONG_COUNT: validationMessages.FILE_WRONG_COUNT,
        SELECTION_REQUIRED: validationMessages.SELECTION_REQUIRED,
        DECLARATION_REQUIRED: validationMessages.DECLARATION_REQUIRED,
        TOO_MANY_CHARACTERS_INPUT: validationMessages.TOO_MANY_CHARACTERS_INPUT,
        TOO_MANY_CHARACTERS_TEXTAREA:
          validationMessages.TOO_MANY_CHARACTERS_TEXTAREA,
      },
      declarationHeading: 'Declaration of consent to data protection',
      declarationText1: 'I have taken note of the ',
      declarationText2: 'privacy policy',
      declarationText3: ` and give my consent to the collection and use of my data entered above.`,
      declarationSiteLinkAddress: 'https://www.enercon.de/en/data-policy',
      requiredField: 'The fields marked with * are mandatory.',
      submit: 'Submit',
      selectedLanguage: 'EN',
      successUrl: '/en/contact/success',
    },
    de: {
      heading: 'Kontaktformular',
      personalInformation: 'Persönliche Informationen',
      residence: 'Wohnort',
      interest: 'Wofür interessierst Du Dich?',
      attachment: 'Anhang',
      others: 'Sonstiges',
      contact: 'Kontakt',
      message: 'Ihre Nachricht',
      formFields: {
        salutation: 'Anrede',
        title: 'Titel',
        firstName: 'Vorname',
        lastName: 'Nachname',
        phoneNumber: 'Telefonnummer',
        street: 'Straße',
        houseNumber: 'Hausnummer',
        postalCode: 'Postleitzahl',
        city: 'Ort',
        addressSuffix: 'Adresszusatz',
        country: 'Land',
        countrySearchPlaceholder: 'Land suchen',
        department: 'Fachbereich',
        fileType: 'Lebenslauf, Bewerbung etc.',
        uploadText: 'Dokument hochladen',
        typeText: 'PDF (maximal 10MB)',
        fileButtonText: 'Durchsuchen',
        uploadDocument: 'Datei hochladen',
        awareness:
          'Ich bin durch folgenden Kanal auf ENERCON aufmerksam geworden',
        message: 'Nachricht bei Fragen oder Anmerkungen',
        salutationValues: SALUTATIONS_GERMAN,
        countryValues: COUNTRIES_GERMAN,
        departmentValues: DEPARTMENTS_GERMAN,
        channelValues: CHANNELS_GERMAN,
        dropdownSelect: 'Auswählen',
        dropdownRemove: 'Auswahl löschen',
      },
      validationMessages: {
        FIELD_REQUIRED: validationMessagesGerman.FIELD_REQUIRED,
        EMAIL_NOT_VALID: validationMessagesGerman.EMAIL_NOT_VALID,
        FILE_WRONG_TYPE: validationMessagesGerman.FILE_WRONG_TYPE,
        FILE_WRONG_SIZE: validationMessagesGerman.FILE_WRONG_SIZE,
        FILE_WRONG_COUNT: validationMessagesGerman.FILE_WRONG_COUNT,
        SELECTION_REQUIRED: validationMessagesGerman.SELECTION_REQUIRED,
        DECLARATION_REQUIRED: validationMessagesGerman.DECLARATION_REQUIRED,
        TOO_MANY_CHARACTERS_INPUT:
          validationMessagesGerman.TOO_MANY_CHARACTERS_INPUT,
        TOO_MANY_CHARACTERS_TEXTAREA:
          validationMessagesGerman.TOO_MANY_CHARACTERS_TEXTAREA,
      },
      declarationHeading: 'Einwilligungserklärung Datenschutz',
      declarationText1: 'Ich habe die ',
      declarationText2: 'Datenschutzerklärung',
      declarationText3: ` zur
      Kenntnis genommen und erteile die Einwilligung in die Erhebung und
      Nutzung meiner vorstehend eingegebenen Daten.`,
      declarationSiteLinkAddress: 'https://www.enercon.de/de/datenschutz',
      requiredField:
        'Die mit einem * gekennzeichneten Felder sind Pflichtfelder.',
      submit: 'Absenden',
      selectedLanguage: 'DE',
      successUrl: '/kontakt/success',
    },
  });

  translations.setLanguage(selectedLanguage);

  const checkFile = (files?: File[]) => {
    if (!files || files.length === 0) {
      return;
    }

    if (files.length > 1) {
      setFilesError(translations.validationMessages.FILE_WRONG_COUNT);
      return;
    }
    const file = files[0];
    if (file.type !== 'application/pdf') {
      setFilesError(translations.validationMessages.FILE_WRONG_TYPE);
    } else if (file.size > 10485760) {
      setFilesError(translations.validationMessages.FILE_WRONG_SIZE);
    } else {
      setFile(file);
      setFilesError('');
    }
  };

  const validation = yup.object().shape({
    salutation: yup
      .string()
      .required(translations.validationMessages.SELECTION_REQUIRED),
    title: yup
      .string()
      .max(50, translations.validationMessages.TOO_MANY_CHARACTERS_INPUT),
    firstName: yup
      .string()
      .required(translations.validationMessages.FIELD_REQUIRED)
      .max(50, translations.validationMessages.TOO_MANY_CHARACTERS_INPUT),
    lastName: yup
      .string()
      .required(translations.validationMessages.FIELD_REQUIRED)
      .max(50, translations.validationMessages.TOO_MANY_CHARACTERS_INPUT),
    street: yup
      .string()
      .required(translations.validationMessages.FIELD_REQUIRED)
      .max(50, translations.validationMessages.TOO_MANY_CHARACTERS_INPUT),
    houseNumber: yup
      .string()
      .required(translations.validationMessages.FIELD_REQUIRED)
      .max(50, translations.validationMessages.TOO_MANY_CHARACTERS_INPUT),
    postalCode: yup
      .string()
      .required(translations.validationMessages.FIELD_REQUIRED)
      .max(50, translations.validationMessages.TOO_MANY_CHARACTERS_INPUT),
    city: yup
      .string()
      .required(translations.validationMessages.FIELD_REQUIRED)
      .max(50, translations.validationMessages.TOO_MANY_CHARACTERS_INPUT),
    addressSuffix: yup
      .string()
      .max(50, translations.validationMessages.TOO_MANY_CHARACTERS_INPUT),
    country: yup
      .string()
      .required(translations.validationMessages.SELECTION_REQUIRED),
    email: yup
      .string()
      .required(translations.validationMessages.FIELD_REQUIRED)
      .matches(
        /^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
        translations.validationMessages.EMAIL_NOT_VALID,
      )
      .max(50, translations.validationMessages.TOO_MANY_CHARACTERS_INPUT),
    phoneNumber: yup
      .string()
      .max(50, translations.validationMessages.TOO_MANY_CHARACTERS_INPUT),
    message: yup
      .string()
      .max(1000, translations.validationMessages.TOO_MANY_CHARACTERS_TEXTAREA),
    declarationOfConsent: yup
      .boolean()
      .test(
        'declaration-of-consent',
        translations.validationMessages.DECLARATION_REQUIRED,
        (value) => value,
      ),
  });

  const formik = useFormik({
    initialValues: {
      salutation: '',
      title: '',
      firstName: '',
      lastName: '',
      street: '',
      houseNumber: '',
      postalCode: '',
      city: '',
      addressSuffix: '',
      country: 'DE',
      email: '',
      phoneNumber: '',
      department: undefined,
      message: '',
      visitedChannel: undefined,
      declarationOfConsent: false,
      captchaToken: '',
      selectedLanguage: translations.selectedLanguage,
    },
    validationSchema: validation,
    onSubmit: (values, { resetForm }) => {
      setFormSubmitting(true);
      postRecruitingFormData({ ...values, file })
        .then(() => {
          resetForm();
          window.location.href = translations.successUrl;
        })
        .catch((error) => showErrorToast(error))
        .finally(() => setFormSubmitting(false))
        .finally(() => setFormSubmitting(false));
    },
  });

  return (
    <RecruitingForm
      countryOpen={countryOpen}
      salutationOpen={salutationOpen}
      departmentOpen={departmentOpen}
      channelOpen={channelOpen}
      setCountryOpen={setCountryOpen}
      setSalutationOpen={setSalutationOpen}
      setDepartmentOpen={setDepartmentOpen}
      setChannelOpen={setChannelOpen}
      onSubmit={formik.handleSubmit}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      values={formik.values}
      touched={formik.touched}
      errors={formik.errors}
      setFieldValue={formik.setFieldValue}
      setFieldTouched={formik.setFieldTouched}
      setFile={checkFile}
      removeFile={() => setFile(undefined)}
      file={file}
      filesError={filesError}
      formSubmitting={formSubmitting}
      translations={translations}
    />
  );
};
