import { backEnds } from '../types/back-ends';
import {
  AfterSalesFormValues,
  CommunicationsFormValues,
  NewsletterFormValues,
  RecruitingFormValues,
  SalesFormValues,
  TrainingCenterFormValues,
} from '../types/form-data-types';
import { convertToFormData } from './helpers';

const convertParticipants = (
  participantNames: string[],
  participantExperiences: string[] | undefined[],
) => {
  const participants = [];
  for (let i = 0; i < participantNames.length; i++) {
    participants.push({
      name: participantNames[i],
      experience: participantExperiences[i],
    });
  }
  return participants;
};

const handleErrors = async (error: any) => {
  const errorDto = await error.json();
  let errorMessage = 'Ein Fehler ist ein unerwarteter Fehler aufgetreten';
  console.log('error: ', errorDto);
  if (errorDto) {
    console.log('error DTO: ', errorDto);
    if (errorDto.message && errorDto.message.length > 0) {
      errorMessage = errorDto.message.join(', ');
    }
  }
  return Promise.reject(errorMessage);
};

export const postSalesFormData = async (data: SalesFormValues) => {
  return fetch(backEnds.SALES, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      captcha: data.captchaToken,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      company: data.company,
      salutation: data.salutation,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phoneNumber: data.phoneNumber,
      country: data.country,
      message: data.message,
      language: data.selectedLanguage,
    }),
  }).then((res) => {
    if (res.ok) {
      return res;
    } else {
      return Promise.reject(res);
    }
  });
};

export const postAfterSalesFormData = async (data: AfterSalesFormValues) => {
  return fetch(backEnds.AFTER_SALES, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      captcha: data.captchaToken,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      company: data.company,
      salutation: data.salutation,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phoneNumber: data.phoneNumber,
      country: data.country,
      projectName: data.projectName,
      projectStatus: data.projectStatus,
      message: data.message,
      language: data.selectedLanguage,
    }),
  }).then((res) => {
    if (res.ok) {
      return res;
    } else {
      return Promise.reject(res);
    }
  });
};

export const postRecruitingFormData = async (data: RecruitingFormValues) => {
  try {
    return fetch(backEnds.RECRUITING, {
      method: 'POST',
      headers: {
        captcha: data.captchaToken,
      },
      body: convertToFormData({
        salutation: data.salutation,
        title: data.title,
        firstName: data.firstName,
        lastName: data.lastName,
        street: data.street,
        houseNumber: data.houseNumber,
        zipCode: data.postalCode,
        city: data.city,
        addressSuffix: data.addressSuffix,
        country: data.country,
        email: data.email,
        phoneNumber: data.phoneNumber,
        department: data.department,
        file1: data.file,
        message: data.message,
        channel: data.visitedChannel,
        language: data.selectedLanguage,
      }),
    })
      .then((res) => {
        if (res.ok) {
          return res;
        } else {
          return Promise.reject(res);
        }
      })
      .catch(handleErrors);
  } catch (error) {
    Promise.reject(error);
  }
};

export const postCommunicationsFormData = async (
  data: CommunicationsFormValues,
) => {
  return fetch(backEnds.COMMUNICATIONS, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      media: data.media,
      salutation: data.salutation,
      firstName: data.firstName,
      lastName: data.name,
      email: data.email,
      phoneNumber: data.phoneNumber,
      message: data.message,
      language: 'DE',
    }),
  }).then((res) => {
    if (res.ok) {
      return res;
    } else {
      return Promise.reject(res);
    }
  });
};

export const postNewsletterFormData = async (data: NewsletterFormValues) => {
  return fetch(backEnds.NEWSLETTER, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
};

export const postTrainingCenterFormData = async (
  data: TrainingCenterFormValues,
) => {
  return fetch(backEnds.TRAINING_CENTER, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      captcha: data.captchaToken,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      trainingType: data.trainingType,
      calendarWeek: data.desiredWeek,
      trainingLanguage: data.trainingLanguage,
      company: data.company,
      email: data.email,
      phoneNumber: data.phoneNumber,
      message: data.message,
      participants: convertParticipants(data.participants, data.experiences),
      language: data.selectedLanguage,
    }),
  }).then((res) => {
    if (res.ok) {
      return res;
    } else {
      return Promise.reject(res);
    }
  });
};
