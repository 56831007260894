import { SelectorType } from '../../../../types/dropdown-types';
import { AnimatePresence, motion } from 'framer-motion';
import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { StyledInputLabel, StyledInputWrapper } from '../styled-components';
import styled from 'styled-components';
import DropdownArrowIcon from '../../../../assets/icons/dropdown-arrow-icon';

const StyledSelectorButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 7px 12px;
  border: 1px solid #b8d9cc;
  background-color: #f7faf8;
  color: #20403c;
  leading-trim: both;
  text-edge: cap;
  font-family: DINCompPro;
  font-size: 17px;
  font-weight: 400;
  line-height: normal;
  appearance: none;
`;

const SpacedDiv = styled.div`
  margin-top: 4px;
`;

const FlexSpan = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  align-items: center;
`;

const FlagIcon = styled.img`
  display: inline;
  margin-right: 8px;
  width: 18px;
  height: 12px;
  border-radius: 2px;
`;

const DropdownDiv = styled.div`
  position: absolute;
  z-index: 10;
  margin-top: 4px;
  width: -moz-calc(100% - 2px);
  width: -webkit-calc(100% - 2px);
  width: -o-calc(100% - 2px);
  width: calc(100% - 2px);
  max-height: 320px;
  border: 1px solid #b8d9cc;
  background-color: #f7faf8;
  box-shadow: 0px 6px 20px 0px rgba(0, 88, 77, 0.06);
  font-size: 17px;
`;

const StickyDiv = styled.div`
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: #f7faf8;
`;

const SearchDiv = styled.div`
  color: #20403c;
  cursor: default;
  user-select: none;
  position: relative;
  margin: 0 12px;
  padding: 16px 0;
  border-bottom: 1px solid #d2e9df;
`;

const SearchInput = styled.input`
  display: block;
  border: none;
  width: 100%;
  font-family: DINCompPro;
  font-size: 17px;
  leading-trim: both;
  text-edge: cap;
  line-height: normal;
  outline-width: 0px;
  background-color: #f7faf8;
`;

const CountryList = styled.div`
  max-height: 256px;
  overflow-y: scroll;
`;

const CountryListErrorMessage = styled.div`
  color: #20403c;
  cursor: default;
  user-select: none;
  position: relative;
  padding: 4px 36px 4px 12px;
`;

const CountryListItem = styled.div`
  color: #20403c;
  font-family: DINCompPro;
  line-height: normal;
  cursor: default;
  user-select: none;
  position: relative;
  padding: 16px 36px 16px 12px;
  border: 2px solid transparent;
  display: flex;
  align-items: center;
  transition-property: border-width;
  transition-duration: 150ms;

  &:hover {
    border-color: #74b95b;
  }
`;

const CountryCheckmark = styled.span` text-blue-600 absolute right-0 flex items-center pr-8
  color: #00584D;
  position: absolute;
  right: 0;
  flex
  align-items: center;
  padding-right: 32px;
`;

const CheckmarkSVG = styled.svg`
  width: 20px;
  height: 20px;
`;

export interface CountrySelectorProps {
  id: string;
  fieldLabel?: string;
  open: boolean;
  disabled?: boolean;
  onToggle: () => void;
  onChange: (value: SelectorType['value']) => void;
  selectedValue?: SelectorType;
  options?: SelectorType[];
  searchPlaceholder?: string;
  required?: boolean;
}

const CountrySelector: React.FC<CountrySelectorProps> = ({
  id,
  fieldLabel,
  open,
  disabled = false,
  onToggle,
  onChange,
  selectedValue,
  options,
  searchPlaceholder,
  required,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const mutableRef = ref as MutableRefObject<HTMLDivElement | null>;

    const handleClickOutside = (event: any) => {
      if (
        mutableRef.current &&
        !mutableRef.current.contains(event.target) &&
        open
      ) {
        onToggle();
        setQuery('');
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onToggle, open, ref]);

  const [query, setQuery] = useState('');

  return (
    <StyledInputWrapper>
      {fieldLabel && (
        <StyledInputLabel>
          {required ? `${fieldLabel} *` : fieldLabel}
        </StyledInputLabel>
      )}
      <div ref={ref}>
        <SpacedDiv>
          <StyledSelectorButton
            type="button"
            aria-haspopup="listbox"
            aria-expanded="true"
            aria-labelledby="listbox-label"
            onClick={onToggle}
            disabled={disabled}
          >
            <FlexSpan>
              {selectedValue?.value !== 'BALTIC_STATES' &&
                selectedValue?.value !== 'OTHER' && (
                  <FlagIcon
                    alt={`${selectedValue?.value}`}
                    src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${selectedValue?.value}.svg`}
                  />
                )}
              {selectedValue?.title}
            </FlexSpan>
            <DropdownArrowIcon />
          </StyledSelectorButton>

          <AnimatePresence>
            {open && (
              <motion.ul
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.1 }}
                tabIndex={-1}
                role="listbox"
                aria-labelledby="listbox-label"
                aria-activedescendant="listbox-option-3"
                style={{ position: 'relative', margin: '0', padding: '0' }}
              >
                <DropdownDiv>
                  <StickyDiv>
                    <SearchDiv>
                      <SearchInput
                        type="search"
                        name="search"
                        autoComplete={'off'}
                        placeholder={searchPlaceholder || 'Search Country'}
                        onChange={(e: any) => setQuery(e.target.value)}
                      />
                    </SearchDiv>
                  </StickyDiv>

                  <CountryList>
                    {options?.filter((option) =>
                      option.title
                        .toLowerCase()
                        .startsWith(query.toLowerCase()),
                    ).length === 0 ? (
                      <CountryListErrorMessage>
                        No countries found
                      </CountryListErrorMessage>
                    ) : (
                      options
                        ?.filter((country) =>
                          country.title
                            .toLowerCase()
                            .startsWith(query.toLowerCase()),
                        )
                        .map((value, index) => {
                          return (
                            <CountryListItem
                              key={`${id}-${index}`}
                              id="listbox-option-0"
                              // eslint-disable-next-line jsx-a11y/role-has-required-aria-props
                              role="option"
                              onClick={() => {
                                onChange(value.value);
                                setQuery('');
                                onToggle();
                              }}
                            >
                              {value.value !== 'BALTIC_STATES' &&
                                value.value !== 'OTHER' && (
                                  <FlagIcon
                                    alt={`${value.value}`}
                                    src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${value.value}.svg`}
                                  />
                                )}

                              <span className="font-normal truncate">
                                {value.title}
                              </span>
                              {value.value === selectedValue?.value ? (
                                <CountryCheckmark>
                                  <CheckmarkSVG
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                      clipRule="evenodd"
                                    />
                                  </CheckmarkSVG>
                                </CountryCheckmark>
                              ) : null}
                            </CountryListItem>
                          );
                        })
                    )}
                  </CountryList>
                </DropdownDiv>
              </motion.ul>
            )}
          </AnimatePresence>
        </SpacedDiv>
      </div>
    </StyledInputWrapper>
  );
};

export default CountrySelector;
