import React from 'react';

const PositionMarkerIcon: React.FC = () => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Frame 266">
      <circle
        id="Ellipse 41"
        cx="13"
        cy="13"
        r="13"
        fill="white"
        fill-opacity="0.31"
      />
      <circle
        id="Ellipse 40"
        cx="13"
        cy="13"
        r="6.94062"
        fill="#6FD34A"
        stroke="white"
        stroke-width="1.88124"
      />
    </g>
  </svg>
);

export default PositionMarkerIcon;
