import React from 'react';
import LocalizedStrings from 'react-localization';
import { Map } from './map';

interface MapSectionProps {
  selectedLanguage: string;
}

export const MapSection: React.FC<MapSectionProps> = ({ selectedLanguage }) => {
  const translations = new LocalizedStrings({
    en: {
      southKorea: 'South Korea',
      australia: 'Australia',
      tuerkiye: 'Türkiye',
      austria: 'Austria',
      bulgaria: 'Bulgaria',
      croatia: 'Croatia',
      estonia: 'Estonia',
      finland: 'Finland',
      germany: 'Germany',
      latvia: 'Latvia',
      lithuania: 'Lithuania',
      norway: 'Norway',
      poland: 'Poland',
      romania: 'Romania',
      sweden: 'Sweden',
      switzerland: 'Switzerland',
      canada: 'Canada',
      argentina: 'Argentina',
      brazil: 'Brazil',
      colombia: 'Colombia',
      greece: 'Greece',
      italy: 'Italy',
      spain: 'Spain',
      france: 'France',
      ireland: 'Ireland',
      greatBritain: 'Great Britain',
      netherlands: 'the Netherlands',
      belgium: 'Belgium',
      luxembourg: 'Luxembourg',
    },
    de: {
      southKorea: 'South Korea',
      australia: 'Australien',
      tuerkiye: 'Türkei',
      austria: 'Österreich',
      bulgaria: 'Bulgarien',
      croatia: 'Kroatien',
      estonia: 'Estland',
      finland: 'Finnland',
      germany: 'Deutschland',
      latvia: 'Lettland',
      lithuania: 'Litauen',
      norway: 'Norwegen',
      poland: 'Polen',
      romania: 'Rumänien',
      sweden: 'Schweden',
      switzerland: 'Schweiz',
      canada: 'Kanada',
      argentina: 'Argentinien',
      brazil: 'Brasilien',
      colombia: 'Kolumbien',
      greece: 'Griechenland',
      italy: 'Italien',
      spain: 'Spanien',
      france: 'Frankreich',
      ireland: 'Irland',
      greatBritain: 'Großbritannien',
      netherlands: 'Niederlande',
      belgium: 'Belgien',
      luxembourg: 'Luxemburg',
    },
  });

  translations.setLanguage(selectedLanguage);

  const markers = [
    {
      left: '82%',
      top: '50%',
      country: 'Taiwan',
      continent: 'Asian Pacific (APAC)',
    },
    {
      left: '86%',
      top: '42%',
      country: 'Japan',
      continent: 'Asian Pacific (APAC)',
    },
    {
      left: '82.6%',
      top: '42%',
      country: translations.southKorea,
      continent: 'Asian Pacific (APAC)',
    },
    {
      left: '78%',
      top: '57%',
      country: 'Vietnam',
      continent: 'Asian Pacific (APAC)',
    },
    {
      left: '85.4%',
      top: '78%',
      country: translations.australia,
      continent: 'Asian Pacific (APAC)',
    },
    {
      left: '56.6%',
      top: '42.2%',
      country: translations.tuerkiye,
      continent: 'Central Asia / Middle East / Africa (CAMEA)',
    },
    {
      left: '50.6%',
      top: '36.4%',
      country: translations.austria,
      continent: 'Central & Northern Europe (CNE)',
    },
    {
      left: '53.6%',
      top: '39.3%',
      country: translations.bulgaria,
      continent: 'Central & Northern Europe (CNE)',
    },
    {
      left: '50.3%',
      top: '38.6%',
      country: translations.croatia,
      continent: 'Central & Northern Europe (CNE)',
    },
    {
      left: '53%',
      top: '27.3%',
      country: translations.estonia,
      continent: 'Central & Northern Europe (CNE)',
    },
    {
      left: '53%',
      top: '23%',
      country: translations.finland,
      continent: 'Central & Northern Europe (CNE)',
    },
    {
      left: '49%',
      top: '33.8%',
      country: translations.germany,
      continent: 'Central & Northern Europe (CNE)',
    },
    {
      left: '53.5%',
      top: '29.3%',
      country: translations.latvia,
      continent: 'Central & Northern Europe (CNE)',
    },
    {
      left: '52.6%',
      top: '31%',
      country: translations.lithuania,
      continent: 'Central & Northern Europe (CNE)',
    },
    {
      left: '48.6%',
      top: '27%',
      country: translations.norway,
      continent: 'Central & Northern Europe (CNE)',
    },
    {
      left: '52.2%',
      top: '33.5%',
      country: translations.poland,
      continent: 'Central & Northern Europe (CNE)',
    },
    {
      left: '53.6%',
      top: '37.4%',
      country: translations.romania,
      continent: 'Central & Northern Europe (CNE)',
    },
    {
      left: '50.6%',
      top: '25%',
      country: translations.sweden,
      continent: 'Central & Northern Europe (CNE)',
    },
    {
      left: '48.4%',
      top: '38.7%',
      country: translations.switzerland,
      continent: 'Central & Northern Europe (CNE)',
    },
    {
      left: '19%',
      top: '23.1%',
      country: translations.canada,
      continent: 'North America (NA)',
    },
    {
      left: '28%',
      top: '82%',
      country: translations.argentina,
      continent: 'Southern Europe and Latin America (SELA)',
    },
    {
      left: '31%',
      top: '68.2%',
      country: translations.brazil,
      continent: 'Southern Europe and Latin America (SELA)',
    },
    {
      left: '26.4%',
      top: '85.5%',
      country: 'Chile',
      continent: 'Southern Europe and Latin America (SELA)',
    },
    {
      left: '25.4%',
      top: '62.4%',
      country: translations.colombia,
      continent: 'Southern Europe and Latin America (SELA)',
    },
    {
      left: '22%',
      top: '58.7%',
      country: 'Costa Rica',
      continent: 'Southern Europe and Latin America (SELA)',
    },
    {
      left: '52.6%',
      top: '43.8%',
      country: translations.greece,
      continent: 'Southern Europe and Latin America (SELA)',
    },
    {
      left: '50.3%',
      top: '41.6%',
      country: translations.italy,
      continent: 'Southern Europe and Latin America (SELA)',
    },
    {
      left: '43.8%',
      top: '41.2%',
      country: 'Portugal',
      continent: 'Southern Europe and Latin America (SELA)',
    },
    {
      left: '46.2%',
      top: '41.5%',
      country: translations.spain,
      continent: 'Southern Europe and Latin America (SELA)',
    },
    {
      left: '31%',
      top: '81.3%',
      country: 'Uruguay',
      continent: 'Southern Europe and Latin America (SELA)',
    },
    {
      left: '46%',
      top: '37%',
      country: translations.france,
      continent: 'Western Europe',
    },
    {
      left: '44%',
      top: '33%',
      country: translations.ireland,
      continent: 'Western Europe',
    },
    {
      left: '46.4%',
      top: '33%',
      country: translations.greatBritain,
      continent: 'Western Europe',
    },
    {
      left: '47.6%',
      top: '34%',
      country: translations.netherlands,
      continent: 'Western Europe',
    },
    {
      left: '47.4%',
      top: '35.8%',
      country: translations.belgium,
      continent: 'Western Europe',
    },
    {
      left: '48.6%',
      top: '36.5%',
      country: translations.luxembourg,
      continent: 'Western Europe',
    },
  ];

  return <Map markers={markers} />;
};
