import { useFormik } from 'formik';
import React, { useState } from 'react';
import * as yup from 'yup';
import {
  validationMessages,
  validationMessagesGerman,
} from '../types/validation-messages';
import { postAfterSalesFormData } from '../services/api';
import { AfterSalesForm } from '../components/contact-form/after-sales-form';
import { showErrorToast } from '../services/toast';
import {
  DISTRIBUTIONS_REGIONS,
  DISTRIBUTIONS_REGIONS_GERMAN,
  PROJECT_STATUS,
  PROJECT_STATUS_GERMAN,
  SALUTATIONS,
  SALUTATIONS_GERMAN,
} from '../components/contact-form/fields/dropdowns/values';
import LocalizedStrings from 'react-localization';

interface AfterSalesFormContainerProps {
  selectedLanguage: string;
}

export const AfterSalesFormContainer: React.FC<
  AfterSalesFormContainerProps
> = ({ selectedLanguage }) => {
  const [countryOpen, setCountryOpen] = useState(false);
  const [salutationOpen, setSalutationOpen] = useState(false);
  const [projectStatusOpen, setProjectStatusOpen] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);

  const translations = new LocalizedStrings({
    en: {
      heading: 'Contact form',
      contactPerson: 'Contact person',
      contact: 'Contact details',
      windFarmInformation: 'Information about your wind farm project',
      message: 'Your Message',
      formFields: {
        company: 'Company',
        salutation: 'Salutation',
        firstName: 'First name',
        lastName: 'Last name',
        phoneNumber: 'Phone',
        country: 'Country / region',
        countrySearchPlaceholder: 'Search country / region',
        projectName: 'Project name',
        projectNamePlaceholder: 'W-number / location',
        projectStatus: 'Project status',
        message: 'Project / wind farm',
        salutationValues: SALUTATIONS,
        regionValues: DISTRIBUTIONS_REGIONS,
        projectStatusValues: PROJECT_STATUS,
        dropdownSelect: 'Choose',
        dropdownRemove: 'Remove selection',
      },
      validationMessages: {
        FIELD_REQUIRED: validationMessages.FIELD_REQUIRED,
        EMAIL_NOT_VALID: validationMessages.EMAIL_NOT_VALID,
        FILE_WRONG_TYPE: validationMessages.FILE_WRONG_TYPE,
        FILE_WRONG_SIZE: validationMessages.FILE_WRONG_SIZE,
        FILE_WRONG_COUNT: validationMessages.FILE_WRONG_COUNT,
        SELECTION_REQUIRED: validationMessages.SELECTION_REQUIRED,
        DECLARATION_REQUIRED: validationMessages.DECLARATION_REQUIRED,
        TOO_MANY_CHARACTERS_INPUT: validationMessages.TOO_MANY_CHARACTERS_INPUT,
        TOO_MANY_CHARACTERS_TEXTAREA:
          validationMessages.TOO_MANY_CHARACTERS_TEXTAREA,
      },
      declarationHeading: 'Declaration of consent to data protection',
      declarationText1: 'I have taken note of the ',
      declarationText2: 'privacy policy',
      declarationText3: ` and give my consent to the collection and use of my data entered above.`,
      declarationSiteLinkAddress: 'https://www.enercon.de/en/data-policy',
      requiredField: 'The fields marked with * are mandatory.',
      submit: 'Submit',
      selectedLanguage: 'EN',
      successUrl: '/en/contact/success',
    },
    de: {
      heading: 'Kontaktformular',
      contactPerson: 'Ansprechpartner',
      contact: 'Kontakt',
      windFarmInformation: 'Informationen zu Ihrem Windparkprojekt',
      message: 'Ihre Nachricht',
      formFields: {
        company: 'Firma',
        salutation: 'Anrede',
        firstName: 'Vorname',
        lastName: 'Nachname',
        phoneNumber: 'Telefonnummer',
        country: 'Land / Region',
        countrySearchPlaceholder: 'Land / Region suchen',
        projectName: 'Projektname',
        projectNamePlaceholder: 'W-Nummer',
        projectStatus: 'Projektstatus',
        message: 'Projekt / Windpark',
        salutationValues: SALUTATIONS_GERMAN,
        regionValues: DISTRIBUTIONS_REGIONS_GERMAN,
        projectStatusValues: PROJECT_STATUS_GERMAN,
        dropdownSelect: 'Auswählen',
        dropdownRemove: 'Auswahl löschen',
      },
      validationMessages: {
        FIELD_REQUIRED: validationMessagesGerman.FIELD_REQUIRED,
        EMAIL_NOT_VALID: validationMessagesGerman.EMAIL_NOT_VALID,
        FILE_WRONG_TYPE: validationMessagesGerman.FILE_WRONG_TYPE,
        FILE_WRONG_SIZE: validationMessagesGerman.FILE_WRONG_SIZE,
        FILE_WRONG_COUNT: validationMessagesGerman.FILE_WRONG_COUNT,
        SELECTION_REQUIRED: validationMessagesGerman.SELECTION_REQUIRED,
        DECLARATION_REQUIRED: validationMessagesGerman.DECLARATION_REQUIRED,
        TOO_MANY_CHARACTERS_INPUT:
          validationMessagesGerman.TOO_MANY_CHARACTERS_INPUT,
        TOO_MANY_CHARACTERS_TEXTAREA:
          validationMessagesGerman.TOO_MANY_CHARACTERS_TEXTAREA,
      },
      declarationHeading: 'Einwilligungserklärung Datenschutz',
      declarationText1: 'Ich habe die ',
      declarationText2: 'Datenschutzerklärung',
      declarationText3: ` zur
      Kenntnis genommen und erteile die Einwilligung in die Erhebung und
      Nutzung meiner vorstehend eingegebenen Daten.`,
      declarationSiteLinkAddress: 'https://www.enercon.de/de/datenschutz',
      requiredField:
        'Die mit einem * gekennzeichneten Felder sind Pflichtfelder.',
      submit: 'Absenden',
      selectedLanguage: 'DE',
      successUrl: '/kontakt/success',
    },
  });

  translations.setLanguage(selectedLanguage);

  const validation = yup.object().shape({
    company: yup
      .string()
      .max(50, translations.validationMessages.TOO_MANY_CHARACTERS_INPUT),
    salutation: yup
      .string()
      .required(translations.validationMessages.SELECTION_REQUIRED),
    lastName: yup
      .string()
      .required(translations.validationMessages.FIELD_REQUIRED)
      .max(50, translations.validationMessages.TOO_MANY_CHARACTERS_INPUT),
    firstName: yup
      .string()
      .required(translations.validationMessages.FIELD_REQUIRED)
      .max(50, translations.validationMessages.TOO_MANY_CHARACTERS_INPUT),
    email: yup
      .string()
      .required(translations.validationMessages.FIELD_REQUIRED)
      .matches(
        /^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
        translations.validationMessages.EMAIL_NOT_VALID,
      )
      .max(50, translations.validationMessages.TOO_MANY_CHARACTERS_INPUT),
    phoneNumber: yup
      .string()
      .max(50, translations.validationMessages.TOO_MANY_CHARACTERS_INPUT),
    country: yup
      .string()
      .required(translations.validationMessages.TOO_MANY_CHARACTERS_INPUT),
    projectName: yup
      .string()
      .max(50, translations.validationMessages.TOO_MANY_CHARACTERS_INPUT),
    message: yup
      .string()
      .required(translations.validationMessages.FIELD_REQUIRED)
      .max(1000, translations.validationMessages.TOO_MANY_CHARACTERS_TEXTAREA),
    declarationOfConsent: yup
      .boolean()
      .test(
        'declaration-of-consent',
        translations.validationMessages.DECLARATION_REQUIRED,
        (value) => value,
      ),
  });

  const formik = useFormik({
    initialValues: {
      company: '',
      salutation: '',
      lastName: '',
      firstName: '',
      email: '',
      phoneNumber: '',
      country: 'DE',
      projectName: '',
      projectStatus: undefined,
      message: '',
      declarationOfConsent: false,
      captchaToken: '',
      selectedLanguage: translations.selectedLanguage,
    },
    validationSchema: validation,
    onSubmit: (values, { resetForm }) => {
      setFormSubmitting(true);
      postAfterSalesFormData(values)
        .then(() => {
          resetForm();
          window.location.href = translations.successUrl;
        })
        .catch((error) => showErrorToast(error))
        .finally(() => setFormSubmitting(false));
    },
  });

  return (
    <AfterSalesForm
      countryOpen={countryOpen}
      salutationOpen={salutationOpen}
      projectStatusOpen={projectStatusOpen}
      setCountryOpen={setCountryOpen}
      setSalutationOpen={setSalutationOpen}
      setProjectStatusOpen={setProjectStatusOpen}
      onSubmit={formik.handleSubmit}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      values={formik.values}
      touched={formik.touched}
      errors={formik.errors}
      setFieldValue={formik.setFieldValue}
      setFieldTouched={formik.setFieldTouched}
      formSubmitting={formSubmitting}
      translations={translations}
    />
  );
};
