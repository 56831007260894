import React, { useRef, useState, DragEvent } from 'react';
import styled from 'styled-components';
import CloudUploadIcon from '../../../assets/icons/cloud-upload-icon';
import UploadedFileIcon from '../../../assets/icons/uploaded-file-icon';
import XIcon from '../../../assets/icons/x-icon';
import {
  StyledInputLabel,
  StyledInputWrapper,
  StyledParagraph,
  StyledSmallParagraph,
  StyleErrorLabel,
} from './styled-components';

const StyledWrapper = styled(StyledInputWrapper)`
  height: max-content;
`;

const StyledOuterLabel = styled.label`
  display: block;
  padding: 3px;
  border: 1px dashed #84b1a1;

  &.fileAdded {
    border: 1px solid ##00584d;
  }

  &.highlight {
    border: 1px solid #74b95b;
  }
`;

const StyledInnerLabel = styled.label`
  padding: 12px 15px;
  margin: auto 0;
  color: #00584d;
  leading-trim: both;
  text-edge: cap;
  font-family: DINCompPro;
  font-size: 15px;
  font-weight: 400;
  line-height: 70%;
  border: 1px solid #84b1a1;

  &.fileAdded {
    width: 44px;
    height: 44px;
    padding: 10px;
  }

  &.fileAdded:hover {
    background-color: #4c8a7a;
  }

  @media (max-width: 500px) {
    margin: auto;
  }
`;

const StyledOuterFlex = styled.div`
  width: 100%;
  height: 79px;
  padding: 0 16px;
  background-color: #f7faf8;
  display: flex;
  justify-content: space-between;

  &.fileAdded {
    background-color: #00584d;
  }

  @media (max-width: 500px) {
    flex-direction: column;
    height: 200px;
  }
`;

const StyledInnerFlex = styled.div`
  display: flex;
  height 100%;

  @media (max-width: 500px) {
    flex-direction: column;
    margin-bottom: 60px;
  }
`;

const StyledIcon = styled.div`
  width: 34px;
  margin: auto 15px auto 0;

  @media (max-width: 500px) {
    margin: 0 auto;
  }
`;

const StyledCenteredText = styled.div`
  margin: auto;
`;

const StyledFileText = styled(StyledParagraph)`
  margin: 0;
  color: #004038;
  leading-trim: both;
  text-edge: cap;

  &.fileAdded {
    color: white;
  }
`;

const StyledSizeText = styled(StyledSmallParagraph)`
  margin: 0;
  color: #90aaa6;

  &.fileAdded {
    color: #b8d9cc;
  }
`;

const StyledInput = styled.input`
  position: absolute;
  width: 100%;
  height: 80px;
  display: none;
`;

interface FileDragAndDropProps {
  fieldLabel?: string;
  uploadText: string;
  typeText: string;
  buttonText: string;
  name: string;
  file?: File;
  onChange: (files: File[]) => void;
  onRemoveFile: () => void;
  onBlur?: (e: React.FormEvent<HTMLInputElement>) => void;
  required?: boolean;
  error?: string | string[];
  dataCy?: string;
  dataCyInput?: string;
  dataCyButton?: string;
}

export const FileDragAndDrop: React.FC<FileDragAndDropProps> = ({
  fieldLabel,
  uploadText,
  typeText,
  buttonText,
  name,
  file,
  onChange,
  onRemoveFile,
  onBlur,
  required,
  error,
  dataCy,
  dataCyInput,
  dataCyButton,
}) => {
  // Define where files can be dropped
  const dropArea = useRef<null | HTMLLabelElement>(null);
  const [isHighlighted, setIsHighlighted] = useState(false);

  // Handle the files on the onChange()
  const handleFiles = (files: File[]) => {
    onChange(files);
  };

  // Give the files to the onChange when dropped off
  const handleDrop = (e: DragEvent<HTMLLabelElement>) => {
    e.preventDefault();

    const files = e.dataTransfer.files ? e.dataTransfer.files : undefined;

    setIsHighlighted(false);
    if (!files) return;

    handleFiles(Array.from(files));
  };

  const handleDragOver = (e: DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
    setIsHighlighted(true);
  };
  const handleDragLeave = (e: DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
    setIsHighlighted(false);
  };

  // Handle file input through the users system file explorer
  const selectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    // only use first file - no multi file upload
    const file = files ? files[0] : undefined;

    if (!file) return;

    handleFiles([file]);
  };

  const handleClickButton = (e: React.MouseEvent<HTMLLabelElement>) => {
    if (file) {
      e.preventDefault();
      onRemoveFile();
    }
  };

  return (
    <StyledWrapper data-cy={dataCy}>
      <StyledInput
        name={name}
        type="file"
        onChange={selectFile}
        onBlur={onBlur}
        id="file-upload"
        accept="application/pdf"
        data-cy={dataCyInput}
        multiple
      />
      {fieldLabel && (
        <StyledInputLabel>
          {required ? `${fieldLabel} *` : fieldLabel}
        </StyledInputLabel>
      )}
      <StyledOuterLabel
        htmlFor="file-upload"
        ref={dropArea}
        className={file ? 'fileAdded' : isHighlighted ? 'highlight' : ''}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <StyledOuterFlex className={file && 'fileAdded'}>
          <StyledInnerFlex>
            <StyledIcon>
              {file ? <UploadedFileIcon /> : <CloudUploadIcon />}
            </StyledIcon>
            <StyledCenteredText>
              <StyledFileText className={file && 'fileAdded'}>
                {file ? file.name : uploadText}
              </StyledFileText>
              <StyledSizeText className={file && 'fileAdded'}>
                {file
                  ? `${Math.ceil((file.size / 1048576) * 10) / 10} MB`
                  : typeText}
              </StyledSizeText>
            </StyledCenteredText>
          </StyledInnerFlex>
          <StyledInnerLabel
            htmlFor="file-upload"
            className={file && 'fileAdded'}
            onClick={handleClickButton}
            data-cy={dataCyButton}
          >
            {file ? <XIcon /> : buttonText}
          </StyledInnerLabel>
        </StyledOuterFlex>
      </StyledOuterLabel>
      {error && <StyleErrorLabel>{error}</StyleErrorLabel>}
    </StyledWrapper>
  );
};
