import React from 'react';
import { DoubleLayout } from './wrappers/double-layout';
import { FormSection } from './wrappers/form-section';
import { InputField } from './fields/input-field';
import { Textarea } from './fields/textarea';
import { Checkbox } from './fields/checkbox';
import { Button } from './fields/button';
import CountrySelector from './fields/dropdowns/selector';
import { CountryMenuOption } from '../../types/dropdown-types';
import {
  StyledBoldParagraph,
  StyledButtonParagraph,
  StyledFormDiv,
  StyledFormHeading,
  StyledLink,
  StyledParagraph,
  StyledSmallParagraph,
  StyledSpacedFlex,
} from './fields/styled-components';
import styled from 'styled-components';
import Dropdown from './fields/dropdowns/dropdown';
import {
  AfterSalesFormErrors,
  AfterSalesFormTouched,
  AfterSalesFormValues,
} from '../../types/form-data-types';
import { LoadingSpinner } from './fields/loading-spinner';
import { FriendlyCaptcha } from '../friendly-captcha';
import SubmitArrowIcon from '../../assets/icons/submit-arrow-icon';
import { LocalizedStrings } from 'react-localization';

const LoadingButtonSizer = styled.div`
  margin: 0 auto;
  width: 90.484px;
  height: 21px;
`;

const CenteredParagraph = styled(StyledSmallParagraph)`
  margin: auto 0;
`;

interface AfterSalesFormProps {
  countryOpen: boolean;
  salutationOpen: boolean;
  projectStatusOpen: boolean;
  setCountryOpen: (val: boolean) => void;
  setSalutationOpen: (val: boolean) => void;
  setProjectStatusOpen: (val: boolean) => void;
  onSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  onChange: (e: React.ChangeEvent<any>) => void;
  onBlur: (e: React.FormEvent<Element>) => void;
  values: AfterSalesFormValues;
  touched: AfterSalesFormTouched;
  errors: AfterSalesFormErrors;
  setFieldValue: (field: string, value: any) => void;
  setFieldTouched: (field: string, field1: boolean, field2: boolean) => void;
  formSubmitting: boolean;
  translations: LocalizedStrings<any>;
}

export const AfterSalesForm: React.FC<AfterSalesFormProps> = ({
  countryOpen,
  salutationOpen,
  projectStatusOpen,
  setCountryOpen,
  setSalutationOpen,
  setProjectStatusOpen,
  onSubmit,
  onChange,
  onBlur,
  values,
  touched,
  errors,
  setFieldValue,
  setFieldTouched,
  formSubmitting,
  translations,
}) => (
  <StyledFormDiv>
    <StyledFormHeading>{translations.heading}</StyledFormHeading>
    <form onSubmit={onSubmit}>
      <FormSection headingText={translations.contactPerson}>
        <InputField
          name="company"
          value={values.company}
          onChange={onChange}
          onBlur={onBlur}
          fieldLabel={translations.formFields.company}
          error={touched.company ? errors.company : ''}
          autocomplete="organization"
          dataCy="afterSalesFormCompanyField"
          dataCyError="afterSalesFormCompanyFieldError"
        />
        <DoubleLayout>
          <Dropdown
            id="salutation"
            fieldLabel={translations.formFields.salutation}
            onChange={(val) => setFieldValue('salutation', val)}
            open={salutationOpen}
            onToggle={() => setSalutationOpen(!salutationOpen)}
            value={values.salutation}
            placeholder={translations.formFields.dropdownSelect}
            removeSelectionText={translations.formFields.dropdownRemove}
            options={translations.formFields.salutationValues}
            required
            onBlur={() => setFieldTouched('salutation', true, true)}
            error={touched.salutation ? errors.salutation : ''}
            dataCySelector="afterSalesFormSalutationDropdown"
            dataCyOptions="afterSalesFormSalutationOption"
            dataCyError="afterSalesFormSalutationDropdownError"
          />
          <div />
          <InputField
            name="firstName"
            value={values.firstName}
            onChange={onChange}
            onBlur={onBlur}
            fieldLabel={translations.formFields.firstName}
            required
            error={touched.firstName ? errors.firstName : ''}
            autocomplete="given-name"
            dataCy="afterSalesFormFirstNameField"
            dataCyError="afterSalesFormFirstNameFieldError"
          />
          <InputField
            name="lastName"
            value={values.lastName}
            onChange={onChange}
            onBlur={onBlur}
            fieldLabel={translations.formFields.lastName}
            required
            error={touched.lastName ? errors.lastName : ""}
            autocomplete="family-name"
            dataCy="afterSalesFormLastNameField"
            dataCyError="afterSalesFormLastNameFieldError"
          />
        </DoubleLayout>
      </FormSection>

      <FormSection headingText={translations.contact}>
        <DoubleLayout>
          <InputField
            name="email"
            value={values.email}
            onChange={onChange}
            onBlur={onBlur}
            type="email"
            fieldLabel="E-Mail"
            required
            error={touched.email ? errors.email : ''}
            autocomplete="email"
            dataCy="afterSalesFormEmailField"
            dataCyError="afterSalesFormEmailFieldError"
          />
          <InputField
            name="phoneNumber"
            value={values.phoneNumber}
            onChange={onChange}
            onBlur={onBlur}
            fieldLabel={translations.formFields.phoneNumber}
            autocomplete="tel"
            error={touched.phoneNumber ? errors.phoneNumber : ''}
            dataCy="afterSalesFormPhoneNumberField"
            dataCyError="afterSalesFormPhoneNumberFieldError"
          />
          <CountrySelector
            id=""
            fieldLabel={translations.formFields.country}
            open={countryOpen}
            onToggle={() => setCountryOpen(!countryOpen)}
            onChange={(val) => (values.country = val)}
            selectedValue={
              translations.formFields.regionValues.find(
                (option: { value: string }) => option.value === values.country,
              ) as CountryMenuOption
            }
            options={translations.formFields.regionValues}
            searchPlaceholder={translations.formFields.countrySearchPlaceholder}
            required
          />
        </DoubleLayout>
      </FormSection>

      <FormSection headingText={translations.windFarmInformation}>
        <DoubleLayout>
          <InputField
            name="projectName"
            value={values.projectName}
            onChange={onChange}
            onBlur={onBlur}
            placeholder={translations.formFields.projectNamePlaceholder}
            fieldLabel={translations.formFields.projectName}
            error={touched.projectName ? errors.projectName : ''}
            dataCy="afterSalesFormProjectNameField"
            dataCyError="afterSalesFormProjectNameFieldError"
          />
          <Dropdown
            id="projectStatus"
            fieldLabel={translations.formFields.projectStatus}
            onChange={(val) => (values.projectStatus = val)}
            open={projectStatusOpen}
            onToggle={() => setProjectStatusOpen(!projectStatusOpen)}
            value={values.projectStatus}
            placeholder={translations.formFields.dropdownSelect}
            placeholderClickable
            removeSelectionText={translations.formFields.dropdownRemove}
            options={translations.formFields.projectStatusValues}
            dataCySelector="afterSalesFormProjectStatusSelector"
            dataCyOptions="afterSalesFormProjectStatusOption"
          />
        </DoubleLayout>
      </FormSection>

      <FormSection headingText={translations.message}>
        <Textarea
          name="message"
          value={values.message}
          onChange={onChange}
          onBlur={onBlur}
          fieldLabel={translations.formFields.message}
          required
          error={touched.message ? errors.message : ''}
          height="204px"
          dataCy="afterSalesFormMessageField"
          dataCyError="afterSalesFormMessageFieldError"
        />
      </FormSection>

      <FriendlyCaptcha
        onChange={(value) => setFieldValue('captchaToken', value)}
      />

      <FormSection>
        <Checkbox
          id="declarationOfConsent"
          name="declarationOfConsent"
          value={values.declarationOfConsent}
          onChange={onChange}
          onBlur={onBlur}
          error={
            touched.declarationOfConsent ? errors.declarationOfConsent : ''
          }
          dataCy="afterSalesFormConsentCheckbox"
          dataCyError="afterSalesFormConsentCheckboxError"
        >
          <StyledBoldParagraph>
            {translations.declarationHeading}
          </StyledBoldParagraph>
          <StyledParagraph>
            {translations.declarationText1}
            <StyledLink
              href={translations.declarationSiteLinkAddress}
              target="_blank"
              rel="noopener"
            >
              {translations.declarationText2}
            </StyledLink>
            {translations.declarationText3}
          </StyledParagraph>
        </Checkbox>
      </FormSection>
      <StyledSpacedFlex>
        <CenteredParagraph>{translations.requiredField}</CenteredParagraph>
        <Button
          type="submit"
          disabled={values.captchaToken === '' || formSubmitting}
          dataCy="afterSalesFormSubmitButton"
        >
          {formSubmitting ? (
            <LoadingButtonSizer>
              <LoadingSpinner />
            </LoadingButtonSizer>
          ) : (
            <StyledButtonParagraph
              color={
                values.captchaToken === '' || formSubmitting
                  ? '#90AAA6'
                  : 'white'
              }
            >
              {translations.submit} <SubmitArrowIcon />
            </StyledButtonParagraph>
          )}
        </Button>
      </StyledSpacedFlex>
    </form>
  </StyledFormDiv>
);
