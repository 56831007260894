import React from 'react';
import { DoubleLayout } from './wrappers/double-layout';
import { FormSection } from './wrappers/form-section';
import { InputField } from './fields/input-field';
import { Button } from './fields/button';
import { SALUTATIONS } from './fields/dropdowns/values';
import {
  StyledBoldLable,
  StyledButtonParagraph,
  StyledFormDiv,
  StyledFormHeading,
  StyledLink,
  StyledParagraph,
  StyledSmallParagraph,
  StyledSpacedFlex,
} from './fields/styled-components';
import styled from 'styled-components';
import Dropdown from './fields/dropdowns/dropdown';
import {
  NewsletterFormErrors,
  NewsletterFormTouched,
  NewsletterFormValues,
} from '../../types/form-data-types';
import { LoadingSpinner } from './fields/loading-spinner';
import { FriendlyCaptcha } from '../friendly-captcha';
import SubmitArrowIcon from '../../assets/icons/submit-arrow-icon';

const StyledNewsletterFormDiv = styled(StyledFormDiv)`
  max-width: 1024px;
`;

const LoadingButtonSizer = styled.div`
  margin: 0 auto;
  width: 90.484px;
  height: 21px;
`;

const CenteredParagraph = styled(StyledSmallParagraph)`
  margin: auto 0;
`;

const TextSpacer = styled.div`
  margin-top: 15px;
  padding-bottom: 30px;
`;

const LeftHeading = styled(StyledFormHeading)`
  text-align: left;
`;

interface NewsletterFormProps {
  salutationOpen: boolean;
  setSalutationOpen: (val: boolean) => void;
  onSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  onChange: (e: React.ChangeEvent<any>) => void;
  onBlur: (e: React.FormEvent<Element>) => void;
  values: NewsletterFormValues;
  touched: NewsletterFormTouched;
  errors: NewsletterFormErrors;
  setFieldTouched: (field: string, field1: boolean, field2: boolean) => void;
  setFieldValue: (field: string, value: any) => void;
  formSubmitting: boolean;
}

export const NewsletterForm: React.FC<NewsletterFormProps> = ({
  salutationOpen,
  setSalutationOpen,
  onSubmit,
  onChange,
  onBlur,
  values,
  touched,
  errors,
  setFieldTouched,
  setFieldValue,
  formSubmitting,
}) => (
  <StyledNewsletterFormDiv>
    <LeftHeading>
      Jetzt zum Windblatt
      <br />
      Online anmelden:
    </LeftHeading>
    <form onSubmit={onSubmit}>
      <InputField
        name="company"
        value={values.company}
        onChange={onChange}
        onBlur={onBlur}
        fieldLabel="Firma"
        error={touched.company ? errors.company : ''}
        autocomplete="organization"
        dataCy="newsletterFormCompanyField"
        dataCyError="newsletterFormCompanyFieldError"
      />
      <DoubleLayout>
        <Dropdown
          id="salutation"
          fieldLabel="Anrede"
          onChange={(val) => setFieldValue('salutation', val)}
          open={salutationOpen}
          onToggle={() => setSalutationOpen(!salutationOpen)}
          value={values.salutation}
          options={SALUTATIONS}
          required
          onBlur={() => setFieldTouched('salutation', true, true)}
          error={touched.salutation ? errors.salutation : ''}
          dataCySelector="newsletterFormSalutationDropdown"
          dataCyOptions="newsletterFormSalutationOption"
          dataCyError="newsletterFormSalutationDropdownError"
        />
        <div />
        <InputField
          name="name"
          value={values.name}
          onChange={onChange}
          onBlur={onBlur}
          fieldLabel="Name"
          required
          error={touched.name ? errors.name : ''}
          autocomplete="family-name"
          dataCy="newsletterFormLastNameField"
          dataCyError="newsletterFormLastNameFieldError"
        />
        <InputField
          name="firstName"
          value={values.firstName}
          onChange={onChange}
          onBlur={onBlur}
          fieldLabel="Vorname"
          required
          error={touched.firstName ? errors.firstName : ''}
          autocomplete="given-name"
          dataCy="newsletterFormFirstNameField"
          dataCyError="newsletterFormFirstNameFieldError"
        />
      </DoubleLayout>

      <InputField
        name="email"
        value={values.email}
        onChange={onChange}
        onBlur={onBlur}
        type="email"
        fieldLabel="E-Mail"
        required
        error={touched.email ? errors.email : ''}
        autocomplete="email"
        dataCy="newsletterFormEmailField"
        dataCyError="newsletterFormEmailFieldError"
      />

      <FriendlyCaptcha
        onChange={(value) => setFieldValue('captchaToken', value)}
      />

      <FormSection>
        <StyledParagraph>
          <StyledBoldLable>Hinweis: </StyledBoldLable>Dieser Service ist
          selbstverständlich kostenlos und kann jederzeit über den Abmeldelink
          im Newsletter beendet werden.
        </StyledParagraph>
        <TextSpacer>
          <StyledParagraph>
            Weitere Informationen zur Verarbeitung Ihrer Daten finden Sie in
            unserer <StyledLink href="">Datenschutzerklärung</StyledLink>
          </StyledParagraph>
        </TextSpacer>
      </FormSection>
      <StyledSpacedFlex>
        <CenteredParagraph>
          Die mit einem * gekennzeichneten Felder sind Pflichtfelder.
        </CenteredParagraph>
        <Button
          type="submit"
          disabled={values.captchaToken === '' || formSubmitting}
          dataCy="newsletterFormSubmitButton"
        >
          {formSubmitting ? (
            <LoadingButtonSizer>
              <LoadingSpinner />
            </LoadingButtonSizer>
          ) : (
            <StyledButtonParagraph
              color={
                values.captchaToken === '' || formSubmitting
                  ? '#90AAA6'
                  : 'white'
              }
            >
              Absenden <SubmitArrowIcon />
            </StyledButtonParagraph>
          )}
        </Button>
      </StyledSpacedFlex>
    </form>
  </StyledNewsletterFormDiv>
);
