import './App.css';
import { ContactForms } from './components/contact-forms';
import 'react-toastify/dist/ReactToastify.css';

interface AppProps {
  id: string;
}

export function App({ id }: AppProps) {
  return (
    <div className="App">
      <ContactForms id={id} />
    </div>
  );
}

export default App;
