import { DoubleLayout } from './wrappers/double-layout';
import { FormSection } from './wrappers/form-section';
import { InputField } from './fields/input-field';
import { Textarea } from './fields/textarea';
import { Button } from './fields/button';
import {
  ExpandedForm,
  StyledButtonParagraph,
  StyledFormDiv,
  StyledFormHeading,
  StyledInputLabel,
  StyledSmallParagraph,
  StyledSpacedFlex,
} from './fields/styled-components';
import styled from 'styled-components';
import PlusIcon from '../../assets/icons/plus-icon';
import Dropdown from './fields/dropdowns/dropdown';
import {
  TrainingCenterFormErrors,
  TrainingCenterFormTouched,
  TrainingCenterFormValues,
} from '../../types/form-data-types';
import { LoadingSpinner } from './fields/loading-spinner';
import { FriendlyCaptcha } from '../friendly-captcha';
import SubmitArrowIcon from '../../assets/icons/submit-arrow-icon';
import XIconWhite from '../../assets/icons/x-icon-white';
import { LocalizedStrings } from 'react-localization';

const StyledTrainingFormDiv = styled(StyledFormDiv)`
  max-width: 880px;
`;

const CenteredParagraph = styled(StyledSmallParagraph)`
  margin: auto 0;
`;

const ParticipantRow = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 35px 0;
  }
`;

const ParticipantMobileRow = styled.div`
  display: flex;
`;

const ParticipantDivider = styled.div`
  height: 0px;
  border-bottom: 1px solid #d2e9df;
`;

const RemoveButtonWrapper = styled.div`
  margin-left: 10px;
`;

const DropdownWrapper = styled(RemoveButtonWrapper)`
  width: 276px;
  @media (max-width: 768px) {
    width: 100%;
    margin-left: 0px;
  }
  height: 45px;
`;

const RemoveButton = styled.button`
  display: flex;
  align-items: center;
  border: none;
  width: 40px;
  height: 40px;
  background-color: #00584d;
  @media (max-width: 768px) {
    margin-top: 27px;
  }
`;

const Centered = styled(StyledSmallParagraph)`
  margin: auto 0;
`;

const AddButtonMargin = styled.div`
  margin-bottom: 30px;
`;

const InputLabelRightMargin = styled.div`
  margin-right: 120px;
`;

const LoadingButtonSizer = styled.div`
  margin: 0 auto;
  width: 90.484px;
  height: 21px;
`;

interface TrainingCenterFormProps {
  dropdownsOpen: boolean[];
  onDropdownToggle: (dropdownValues: boolean[], index: number) => void;
  addParticipant: () => void;
  removeParticipant: (id: number) => void;
  changeParticipantName: (id: number, value: string) => void;
  changeParticipantExperience: (id: number, value?: string) => void;
  onSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  onChange: (e: React.ChangeEvent<any>) => void;
  onBlur: (e: React.FormEvent<Element>) => void;
  values: TrainingCenterFormValues;
  touched: TrainingCenterFormTouched;
  errors: TrainingCenterFormErrors;
  setFieldValue: (field: string, value: any) => void;
  formSubmitting: boolean;
  viewportWidth: number;
  translations: LocalizedStrings<any>;
}

export const TrainingCenterForm: React.FC<TrainingCenterFormProps> = ({
  dropdownsOpen,
  onDropdownToggle,
  addParticipant,
  removeParticipant,
  changeParticipantName,
  changeParticipantExperience,
  onSubmit,
  onChange,
  onBlur,
  values,
  touched,
  errors,
  setFieldValue,
  formSubmitting,
  viewportWidth,
  translations,
}) => (
  <StyledTrainingFormDiv>
    <StyledFormHeading>{translations.heading}</StyledFormHeading>
    <ExpandedForm onSubmit={onSubmit}>
      <FormSection headingText="Training">
        <InputField
          name="trainingType"
          value={values.trainingType}
          onChange={onChange}
          onBlur={onBlur}
          fieldLabel={translations.formFields.trainingType}
          required
          error={touched.trainingType ? errors.trainingType : ''}
          dataCy="trainingFormTrainingTypeField"
          dataCyError="trainingFormTrainingTypeFieldError"
        />
        <DoubleLayout>
          <InputField
            name="desiredWeek"
            type="number"
            value={values.desiredWeek}
            onChange={(e) =>
              setFieldValue(
                'desiredWeek',
                Math.max(1, Math.min(Number(e.currentTarget.value), 53)),
              )
            }
            onBlur={onBlur}
            fieldLabel={translations.formFields.desiredWeek}
            required
            error={touched.desiredWeek ? errors.desiredWeek : ''}
            dataCy="trainingFormWeekField"
            dataCyError="trainingFormWeekFieldError"
          />
          <InputField
            name="trainingLanguage"
            value={values.trainingLanguage}
            onChange={onChange}
            onBlur={onBlur}
            fieldLabel={translations.formFields.trainingLanguage}
            required
            error={touched.trainingLanguage ? errors.trainingLanguage : ''}
            autocomplete="organization"
            dataCy="trainingFormLanguageField"
            dataCyError="trainingFormLanguageFieldError"
          />
        </DoubleLayout>
      </FormSection>

      <FormSection headingText={translations.companyInformation}>
        <InputField
          name="company"
          value={values.company}
          onChange={onChange}
          onBlur={onBlur}
          fieldLabel={translations.formFields.company}
          error={touched.company ? errors.company : ''}
          autocomplete="organization"
          dataCy="trainingFormCompanyField"
          dataCyError="trainingFormCompanyFieldError"
        />
        <DoubleLayout>
          <InputField
            name="email"
            value={values.email}
            onChange={onChange}
            onBlur={onBlur}
            fieldLabel={translations.formFields.email}
            required
            error={touched.email ? errors.email : ''}
            autocomplete="email"
            dataCy="trainingFormEmailField"
            dataCyError="trainingFormEmailFieldError"
          />
          <InputField
            name="phoneNumber"
            value={values.phoneNumber}
            onChange={onChange}
            onBlur={onBlur}
            fieldLabel={translations.formFields.phoneNumber}
            required
            error={touched.phoneNumber ? errors.phoneNumber : ''}
            autocomplete="tel"
            dataCy="trainingFormPhoneNumberField"
            dataCyError="trainingFormPhoneNumberFieldError"
          />
        </DoubleLayout>
      </FormSection>

      <FormSection headingText={translations.participants}>
        {viewportWidth > 768 && (
          <ParticipantRow>
            <StyledInputLabel>
              {translations.formFields.participantName}
            </StyledInputLabel>
            <InputLabelRightMargin>
              <StyledInputLabel>
                {translations.formFields.participantExperience}
              </StyledInputLabel>
            </InputLabelRightMargin>
          </ParticipantRow>
        )}
        {values.participants.map((value, index) => (
          <div key={index}>
            {index > 0 && viewportWidth <= 768 && <ParticipantDivider />}
            <ParticipantRow>
              <InputField
                name="participants"
                fieldLabel={
                  viewportWidth <= 768
                    ? translations.formFields.participantName
                    : ''
                }
                value={value}
                onChange={(val) =>
                  changeParticipantName(index, val.currentTarget.value)
                }
                onBlur={onBlur}
                placeholder={translations.participantNamePlaceholder}
                error={
                  touched.participants && errors.participants
                    ? errors.participants[index]
                    : ''
                }
                extraStyles={{ paddingBottom: '15px' }}
                dataCy="trainingFormParticipantFullName"
                dataCyError="trainingFormParticipantFullNameError"
              />
              <ParticipantMobileRow>
                <DropdownWrapper>
                  <Dropdown
                    id=""
                    fieldLabel={
                      viewportWidth <= 768
                        ? translations.formFields.participantExperience
                        : ''
                    }
                    value={values.experiences[index]}
                    options={
                      translations.formFields.participantExperienceOptions
                    }
                    onChange={(val) => changeParticipantExperience(index, val)}
                    open={dropdownsOpen[index]}
                    onToggle={() => onDropdownToggle(dropdownsOpen, index)}
                    placeholder={
                      translations.formFields.participantNoExperienceOption
                    }
                    placeholderClickable
                    removeSelectionText={
                      translations.formFields.participantNoExperienceOption
                    }
                    extraStyles={{ paddingBottom: '15px' }}
                    dataCySelector="trainingFormParticipantExperienceSelector"
                    dataCyOptions="trainingFormParticipantExperienceOption"
                  />
                </DropdownWrapper>
                <RemoveButtonWrapper>
                  <RemoveButton
                    onClick={() => removeParticipant(index)}
                    data-cy="trainingFormRemoveParticipantButton"
                  >
                    <XIconWhite />
                  </RemoveButton>
                </RemoveButtonWrapper>
              </ParticipantMobileRow>
            </ParticipantRow>
          </div>
        ))}
        <AddButtonMargin>
          <Button
            onClick={addParticipant}
            design="light"
            dataCy="trainingFormAddParticipantButton"
          >
            <ParticipantMobileRow>
              <PlusIcon />
              <Centered>
                {translations.formFields.addParticipantButton}
              </Centered>
            </ParticipantMobileRow>
          </Button>
        </AddButtonMargin>
      </FormSection>

      <FormSection
        headingText={translations.messageSection}
        extraStyles={{ marginBottom: '20px', paddingBottom: '0px' }}
      >
        <Textarea
          name="message"
          value={values.message}
          onChange={onChange}
          onBlur={onBlur}
          fieldLabel={translations.formFields.message}
          error={touched.message ? errors.message : ''}
          height="204px"
          extraStyles={{ paddingBottom: '20px' }}
          dataCy="trainingFormMessageField"
          dataCyError="trainingFormMessageFieldError"
        />
      </FormSection>

      <FriendlyCaptcha
        onChange={(value) => setFieldValue('captchaToken', value)}
      />

      <StyledSpacedFlex>
        <CenteredParagraph>{translations.requiredFields}</CenteredParagraph>
        <Button
          type="submit"
          disabled={values.captchaToken === '' || formSubmitting}
          dataCy="trainingFormSubmitButton"
        >
          {formSubmitting ? (
            <LoadingButtonSizer>
              <LoadingSpinner />
            </LoadingButtonSizer>
          ) : (
            <StyledButtonParagraph
              color={
                values.captchaToken === '' || formSubmitting
                  ? '#90AAA6'
                  : 'white'
              }
            >
              {translations.submit} <SubmitArrowIcon />
            </StyledButtonParagraph>
          )}
        </Button>
      </StyledSpacedFlex>
    </ExpandedForm>
  </StyledTrainingFormDiv>
);
