import { SelectorType } from '../../../../types/dropdown-types';
import { AnimatePresence, motion } from 'framer-motion';
import React, {
  CSSProperties,
  MutableRefObject,
  useEffect,
  useRef,
} from 'react';
import {
  StyledInputLabel,
  StyledInputWrapper,
  StyleErrorLabel,
} from '../styled-components';
import styled from 'styled-components';
import DropdownArrowIcon from '../../../../assets/icons/dropdown-arrow-icon';

const StyledSelectorButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 7px 12px;
  border: 1px solid #b8d9cc;
  background-color: #f7faf8;
  color: #20403c;
  leading-trim: both;
  text-edge: cap;
  font-family: DINCompPro;
  font-size: 17px;
  font-weight: 400;
  line-height: normal;
  appearance: none;
`;

const FlexSpan = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  align-items: center;
`;

const PlaceholderSpan = styled(FlexSpan)`
  color: #90aaa6;
`;

const DropdownDiv = styled.div`
  position: absolute;
  z-index: 10;
  margin-top: 4px;
  max-height: 320px;
  width: -moz-calc(100% - 2px);
  width: -webkit-calc(100% - 2px);
  width: -o-calc(100% - 2px);
  width: calc(100% - 2px);
  border: 1px solid #b8d9cc;
  background-color: #f7faf8;
  box-shadow: 0px 6px 20px 0px rgba(0, 88, 77, 0.06);
  font-size: 17px;
`;

const StickyDiv = styled.div`
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: #f7faf8;
`;

const SearchDiv = styled.div`
  color: #20403c;
  cursor: default;
  user-select: none;
  position: relative;
  margin: 0 12px;
  padding: 16px 0;
  border-bottom: 1px solid #d2e9df;
`;

const SearchInput = styled.div`
  display: flex;
  align-content: start;
  width: 100%;
  font-family: DINCompPro;
  font-size: 17px;
  leading-trim: both;
  text-edge: cap;
  line-height: normal;
  outline-width: 0px;
  background-color: #f7faf8;
`;

const CountryList = styled.div`
  max-height: 256px;
  overflow-y: scroll;
`;

const CountryListItem = styled.div`
  color: #20403c;
  font-family: DINCompPro;
  line-height: normal;
  cursor: default;
  user-select: none;
  position: relative;
  border: 2px solid transparent;
  padding: 16px 36px 16px 12px;
  display: flex;
  align-items: center;
  transition-property: border-width;
  transition-duration: 150ms;

  &:hover {
    border-color: #74b95b;
  }
`;

const CountryCheckmark = styled.span` text-blue-600 absolute right-0 flex items-center pr-8
  color: #00584D;
  position: absolute;
  right: 0;
  flex
  align-items: center;
  padding-right: 32px;
`;

const CheckmarkSVG = styled.svg`
  width: 20px;
  height: 20px;
`;

export interface CountrySelectorProps {
  id: string;
  fieldLabel?: string;
  open: boolean;
  disabled?: boolean;
  onToggle: () => void;
  onChange: (value?: string) => void;
  onBlur?: () => void;
  value?: string;
  required?: boolean;
  placeholder?: string;
  placeholderClickable?: boolean;
  removeSelectionText?: string;
  options: SelectorType[];
  error?: string;
  extraStyles?: CSSProperties;
  dataCySelector?: string;
  dataCyOptions?: string;
  dataCyPlaceholder?: string;
  dataCyError?: string;
}

const Dropdown: React.FC<CountrySelectorProps> = ({
  id,
  fieldLabel,
  open,
  disabled = false,
  onToggle,
  onChange,
  onBlur,
  value,
  required,
  placeholder,
  placeholderClickable,
  removeSelectionText,
  options,
  error,
  extraStyles,
  dataCySelector,
  dataCyOptions,
  dataCyPlaceholder,
  dataCyError,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const mutableRef = ref as MutableRefObject<HTMLDivElement | null>;

    const handleClickOutside = (event: any) => {
      if (
        mutableRef.current &&
        !mutableRef.current.contains(event.target) &&
        open
      ) {
        onToggle();
        onBlur && onBlur();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onBlur, onToggle, open, ref]);

  const selectedValue = options.find((option) => option.value === value);

  return (
    <StyledInputWrapper style={extraStyles}>
      {fieldLabel && (
        <StyledInputLabel>
          {required ? `${fieldLabel} *` : fieldLabel}
        </StyledInputLabel>
      )}
      <div ref={ref} data-cy={dataCySelector}>
        <StyledSelectorButton
          type="button"
          aria-haspopup="listbox"
          aria-expanded="true"
          aria-labelledby="listbox-label"
          onClick={onToggle}
          disabled={disabled}
        >
          {selectedValue?.title ? (
            <FlexSpan>{selectedValue?.title}</FlexSpan>
          ) : placeholderClickable ? (
            <FlexSpan>{placeholder}</FlexSpan>
          ) : (
            <PlaceholderSpan>{placeholder}</PlaceholderSpan>
          )}
          <DropdownArrowIcon />
        </StyledSelectorButton>

        <AnimatePresence>
          {open && (
            <motion.ul
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.1 }}
              tabIndex={-1}
              role="listbox"
              aria-labelledby="listbox-label"
              aria-activedescendant="listbox-option-3"
              style={{ position: 'relative', margin: '0', padding: '0' }}
            >
              <DropdownDiv>
                <StickyDiv
                  onClick={() => {
                    if (placeholderClickable) {
                      onChange(undefined);
                      onToggle();
                    }
                  }}
                >
                  <SearchDiv data-cy={dataCyPlaceholder}>
                    <SearchInput>{removeSelectionText}</SearchInput>
                  </SearchDiv>
                </StickyDiv>

                <CountryList>
                  {options
                    .filter((option) => option.title.toLowerCase())
                    .map((value, index) => {
                      return (
                        <CountryListItem
                          key={`${id}-${index}`}
                          id="listbox-option-0"
                          // eslint-disable-next-line jsx-a11y/role-has-required-aria-props
                          role="option"
                          onClick={() => {
                            onChange(value.value);
                            onToggle();
                          }}
                        >
                          <span
                            className="font-normal truncate"
                            data-cy={dataCyOptions}
                          >
                            {value.title}
                          </span>
                          {value.value === selectedValue?.value ? (
                            <CountryCheckmark>
                              <CheckmarkSVG
                                className="h-5 w-5"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                  clipRule="evenodd"
                                />
                              </CheckmarkSVG>
                            </CountryCheckmark>
                          ) : null}
                        </CountryListItem>
                      );
                    })}
                </CountryList>
              </DropdownDiv>
            </motion.ul>
          )}
        </AnimatePresence>
      </div>
      {error && (
        <StyleErrorLabel data-cy={dataCyError}>{error}</StyleErrorLabel>
      )}
    </StyledInputWrapper>
  );
};

export default Dropdown;
