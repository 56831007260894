import React from 'react';
import styled from 'styled-components';
import {
  StyledCheckbox,
  StyledCheckboxLabel,
  StyledParagraph,
} from './styled-components';
import RadioboxIcon from '../../../assets/icons/radiobox-icon';

const StyledRadioDiv = styled.label`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 54px;
  border: 1px solid #b8d9cc;
  margin-top: 10px;
`;

const StyledLabelDiv = styled.div`
  display: flex;
  margin: auto 0;
`;

const StyledRadioLabel = styled(StyledParagraph)`
  color: #004038;
  leading-trim: both;
  text-edge: cap;
  margin-left: 12px;
`;

const StyledSelectedRadioLabel = styled(StyledRadioLabel)`
  color: #fff;
`;

const StyledRadioButtonLabel = styled(StyledCheckboxLabel)`
  border-radius: 100%;
  width: 22px;
`;

const StyledRadioButton = styled(StyledCheckbox)`
  &:checked + ${StyledRadioButtonLabel} {
    border: none;
  }
`;

const StyledSelectedRadioDiv = styled(StyledRadioDiv)`
  border-color: #00584d;
  background-color: #00584d;
`;

interface RadiobuttonProps {
  id: string;
  radioLabel: string;
  value?: boolean;
  onChange: () => void;
  radioGroup: string;
  dataCy?: string;
}

export const Radiobutton: React.FC<RadiobuttonProps> = ({
  id,
  radioLabel,
  value,
  onChange,
  radioGroup,
  dataCy,
}) => (
  <>
    {value ? (
      <StyledSelectedRadioDiv htmlFor={id} data-cy={dataCy}>
        <StyledLabelDiv>
          <StyledRadioButton
            name={radioGroup}
            type="radio"
            id={id}
            checked={value}
            onChange={onChange}
          />
          <StyledRadioButtonLabel htmlFor={id}>
            <RadioboxIcon />
          </StyledRadioButtonLabel>
          <StyledSelectedRadioLabel>{radioLabel}</StyledSelectedRadioLabel>
        </StyledLabelDiv>
      </StyledSelectedRadioDiv>
    ) : (
      <StyledRadioDiv htmlFor={id} data-cy={dataCy}>
        <StyledLabelDiv>
          <StyledRadioButton
            name={radioGroup}
            type="radio"
            id={id}
            checked={value}
            onChange={onChange}
          />
          <StyledRadioButtonLabel htmlFor={id} />
          <StyledRadioLabel>{radioLabel}</StyledRadioLabel>
        </StyledLabelDiv>
      </StyledRadioDiv>
    )}
  </>
);
