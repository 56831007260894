import React from 'react';
import { WidgetInstance } from 'friendly-challenge';
import { useRef } from 'react';
import { useEffect } from 'react';

const FRIENDLY_CAPTCHA_SITE_KEY = 'FCMSFL4OHK9SDL9P';

interface FriendlyCaptchaProps {
  onChange: (token: string) => void;
}

export const FriendlyCaptcha: React.FC<FriendlyCaptchaProps> = ({
  onChange,
}) => {
  const captchaWidgetRef = useRef(null);

  let widget = useRef<WidgetInstance | undefined>(undefined);
  useEffect(() => {
    const el = captchaWidgetRef.current;
    if (el) {
      widget.current = new WidgetInstance(el, {
        // The captcha was completed
        doneCallback: (solution) => {
          onChange(solution);
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className="frc-captcha"
      ref={captchaWidgetRef}
      data-sitekey={FRIENDLY_CAPTCHA_SITE_KEY}
    ></div>
  );
};
