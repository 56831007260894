export const SALUTATIONS_GERMAN = [
  {
    title: 'Herr',
    value: 'MR',
  },
  {
    title: 'Frau',
    value: 'MRS',
  },
  {
    title: 'Divers',
    value: 'OTHER',
  },
];

export const SALUTATIONS = [
  {
    title: 'Mr',
    value: 'MR',
  },
  {
    title: 'Mrs',
    value: 'MRS',
  },
  {
    title: 'Diverse',
    value: 'OTHER',
  },
];

export const PROJECT_STATUS = [
  {
    title: 'In planning',
    value: 'PLANNED',
  },
  {
    title: 'In execution',
    value: 'UNDER_CONSTRUCTION',
  },
  {
    title: 'Commissioned',
    value: 'DONE',
  },
];

export const PROJECT_STATUS_GERMAN = [
  {
    title: 'In Planung',
    value: 'PLANNED',
  },
  {
    title: 'In Umsetzung',
    value: 'UNDER_CONSTRUCTION',
  },
  {
    title: 'Fertiggestellt',
    value: 'DONE',
  },
];

export const EXPERIENCE = [
  {
    title: 'Beginner',
    value: 'BEGINNER',
  },
  {
    title: 'Intermediate',
    value: 'ADVANCED',
  },
  {
    title: 'Expert',
    value: 'EXPERT',
  },
];

export const EXPERIENCE_GERMAN = [
  {
    title: 'Anfänger',
    value: 'BEGINNER',
  },
  {
    title: 'Fortgeschritten',
    value: 'ADVANCED',
  },
  {
    title: 'Experte',
    value: 'EXPERT',
  },
];

export const DEPARTMENTS_GERMAN = [
  {
    title: 'Forschung & Entwicklung',
    value: 'RESEARCH_AND_DEVELOPMENT',
  },
  {
    title: 'Vertrieb',
    value: 'SALES',
  },
  {
    title: 'Projektmanagement',
    value: 'PROJECT_MANAGEMENT',
  },
  {
    title: 'Service',
    value: 'SERVICE',
  },
  {
    title: 'Verwaltung',
    value: 'ADMINISTRATION',
  },
  {
    title: 'Produktion',
    value: 'PRODUCTION',
  },
  {
    title: 'Logistik',
    value: 'LOGISTICS',
  },
  {
    title: 'IT',
    value: 'IT',
  },
  {
    title: 'Weiteres',
    value: 'OTHER',
  },
];

export const DEPARTMENTS = [
  {
    title: 'Research & Development',
    value: 'RESEARCH_AND_DEVELOPMENT',
  },
  {
    title: 'Sales',
    value: 'SALES',
  },
  {
    title: 'Project Management',
    value: 'PROJECT_MANAGEMENT',
  },
  {
    title: 'Service',
    value: 'SERVICE',
  },
  {
    title: 'Administration',
    value: 'ADMINISTRATION',
  },
  {
    title: 'Production',
    value: 'PRODUCTION',
  },
  {
    title: 'Logistics',
    value: 'LOGISTICS',
  },
  {
    title: 'IT',
    value: 'IT',
  },
  {
    title: 'Others',
    value: 'OTHER',
  },
];

export const CHANNELS_GERMAN = [
  {
    title: 'Messen/Veranstaltungen',
    value: 'EVENT',
  },
  {
    title: 'Familie/Bekannte',
    value: 'ACQUAINTANCE',
  },
  {
    title: 'Instagram',
    value: 'INSTAGRAM',
  },
  {
    title: 'LinkedIn',
    value: 'LINKEDIN',
  },
  {
    title: 'Anzeigen',
    value: 'ADVERTISEMENT',
  },
  {
    title: 'Webseite',
    value: 'WEBSITE',
  },
  {
    title: 'Jobportale',
    value: 'JOB_PORTAL',
  },
  {
    title: 'Sonstiges',
    value: 'OTHER',
  },
];

export const CHANNELS = [
  {
    title: 'Trade fair/evens',
    value: 'EVENT',
  },
  {
    title: 'Family/friends',
    value: 'ACQUAINTANCE',
  },
  {
    title: 'Instagram',
    value: 'INSTAGRAM',
  },
  {
    title: 'LinkedIn',
    value: 'LINKEDIN',
  },
  {
    title: 'Advertisement',
    value: 'ADVERTISEMENT',
  },
  {
    title: 'Website',
    value: 'WEBSITE',
  },
  {
    title: 'Jobportals',
    value: 'JOB_PORTAL',
  },
  {
    title: 'Others',
    value: 'OTHER',
  },
];

export const COUNTRIES_GERMAN = [
  {
    title: 'Argentinien',
    value: 'AR',
  },
  {
    title: 'Australien',
    value: 'AU',
  },
  {
    title: 'Brasilien',
    value: 'BR',
  },
  {
    title: 'Bulgarien',
    value: 'BG',
  },
  {
    title: 'Chile',
    value: 'CL',
  },
  {
    title: 'Costa Rica',
    value: 'CR',
  },
  {
    title: 'Deutschland',
    value: 'DE',
  },
  {
    title: 'Estland',
    value: 'EE',
  },
  {
    title: 'Finnland',
    value: 'FI',
  },
  {
    title: 'Griechenland',
    value: 'GR',
  },
  {
    title: 'Italien',
    value: 'IT',
  },
  {
    title: 'Japan',
    value: 'JP',
  },
  {
    title: 'Kanada',
    value: 'CA',
  },
  {
    title: 'Kolumbien',
    value: 'CO',
  },
  {
    title: 'Kroatien',
    value: 'HR',
  },
  {
    title: 'Lettland',
    value: 'LV',
  },
  {
    title: 'Litauen',
    value: 'LT',
  },
  {
    title: 'Norwegen',
    value: 'NO',
  },
  {
    title: 'Polen',
    value: 'PL',
  },
  {
    title: 'Portugal',
    value: 'PT',
  },
  {
    title: 'Rumänien',
    value: 'RO',
  },
  {
    title: 'Schweden',
    value: 'SE',
  },
  {
    title: 'Schweiz',
    value: 'CH',
  },
  {
    title: 'Spanien',
    value: 'ES',
  },
  {
    title: 'Südkorea',
    value: 'KR',
  },
  {
    title: 'Taiwan',
    value: 'TW',
  },
  {
    title: 'Türkei',
    value: 'TR',
  },
  {
    title: 'Vietnam',
    value: 'VN',
  },
  {
    title: 'Österreich',
    value: 'AT',
  },
  {
    title: 'Andere Länder',
    value: 'OTHER',
  },
];

export const COUNTRIES = [
  {
    title: 'Argentina',
    value: 'AR',
  },
  {
    title: 'Australia',
    value: 'AU',
  },
  {
    title: 'Austria',
    value: 'AT',
  },
  {
    title: 'Brazil',
    value: 'BR',
  },
  {
    title: 'Bulgaria',
    value: 'BG',
  },
  {
    title: 'Canada',
    value: 'CA',
  },
  {
    title: 'Chile',
    value: 'CL',
  },
  {
    title: 'Colombia',
    value: 'CO',
  },
  {
    title: 'Costa Rica',
    value: 'CR',
  },
  {
    title: 'Croatia',
    value: 'HR',
  },
  {
    title: 'Estonia',
    value: 'EE',
  },
  {
    title: 'Finland',
    value: 'FI',
  },
  {
    title: 'Germany',
    value: 'DE',
  },
  {
    title: 'Greece',
    value: 'GR',
  },
  {
    title: 'Italy',
    value: 'IT',
  },
  {
    title: 'Japan',
    value: 'JP',
  },
  {
    title: 'Latvia',
    value: 'LV',
  },
  {
    title: 'Lithuania',
    value: 'LT',
  },
  {
    title: 'Norway',
    value: 'NO',
  },
  {
    title: 'Poland',
    value: 'PL',
  },
  {
    title: 'Portugal',
    value: 'PT',
  },
  {
    title: 'Romania',
    value: 'RO',
  },
  {
    title: 'South Korea',
    value: 'KR',
  },
  {
    title: 'Spain',
    value: 'ES',
  },
  {
    title: 'Sweden',
    value: 'SE',
  },
  {
    title: 'Switzerland',
    value: 'CH',
  },
  {
    title: 'Taiwan',
    value: 'TW',
  },
  {
    title: "Türkiye",
    value: "TR",
  },
  {
    title: 'Vietnam',
    value: 'VN',
  },
  {
    title: 'Other Countries',
    value: 'OTHER',
  },
];

export const DISTRIBUTIONS_REGIONS_GERMAN = [
  ...COUNTRIES_GERMAN,
  {
    title: 'Baltikum',
    value: 'BALTIC_STATES',
  },
];

export const DISTRIBUTIONS_REGIONS = [
  ...COUNTRIES,
  {
    title: 'Baltic states',
    value: 'BALTIC_STATES',
  },
];
